import axios from "axios";
import { nordNxtApiEndpoint } from "../../config/config";
import { Cookies } from "react-cookie";
import { cookieNames } from "../../config/cookieNames";

export default async function requestNewAccessToken() {
  const cookies = new Cookies();
  const refreshToken = cookies.get(cookieNames.refreshToken);
  const { data } = await axios.get(
    `${nordNxtApiEndpoint}/request-access-token`,
    {
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    },
  );
  return data;
}
