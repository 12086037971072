import useUserSettings from "../contexts/UserSettingsProvider";
import useScreenResizeListener from "./useScreenResizeListener";
import sendGaEvent from "../services/sendGaEvent";

export default function useHandleNavlinkClick(gaEventCategory: string) {
  const { toggleSidebar } = useUserSettings();
  const { isScreenWidthSmallerThan1024 } = useScreenResizeListener();

  const handleNavlinkClick = (gaEventAction: string) => {
    isScreenWidthSmallerThan1024 && toggleSidebar();
    sendGaEvent(gaEventCategory, gaEventAction);
  };

  return { handleNavlinkClick };
}
