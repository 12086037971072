function userReducer(state, action) {
  switch (action.type) {
    case "signIn":
      return { ...action.payload };
    case "signOut":
      return null;
    case "changeEmail":
      return { ...state, email: action.payload };
    // NOTE: add changeContactNumber once OTP SMS feature is added
    default:
      return state;
  }
}

export default userReducer;
