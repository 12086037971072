import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Error404 from "../pages/404/Error404";
import Layout from "./Layout/Layout";
import Loader from "./Loader";
import lazyRetry from "../services/lazyRetry";
import useAuth from "../contexts/AuthProvider";
import LoadingFallback from "../pages/loadingFallback/LoadingFallback";
const ForgotPassword = lazy(() =>
  lazyRetry(() => import("../pages/auth/ForgotPassword")),
);
const ResetPassword = lazy(() =>
  lazyRetry(() => import("../pages/auth/ResetPassword")),
);
const ResetPasswordSucess = lazy(() =>
  lazyRetry(() => import("../pages/auth/ResetPasswordSuccess")),
);
const ResetPasswordVerification = lazy(() =>
  lazyRetry(() => import("../pages/auth/ResetPasswordVerification")),
);
const Dashboard = lazy(() => lazyRetry(() => import("../pages/dashboard")));
const Dca = lazy(() => lazyRetry(() => import("../pages/dca")));
const AllDcas = lazy(() => lazyRetry(() => import("../pages/dca/AllDcas")));
const DcaDetails = lazy(() =>
  lazyRetry(() => import("../pages/dca/DcaDetails")),
);
const Faq = lazy(() => lazyRetry(() => import("../pages/help/Faq")));
const Invest = lazy(() => lazyRetry(() => import("../pages/invest")));
const InvestDetails = lazy(() =>
  lazyRetry(() => import("../pages/invest/InvestDetails")),
);
const OrdersPage = lazy(() => lazyRetry(() => import("../pages/order")));
const Portfolio = lazy(() => lazyRetry(() => import("../pages/portfolio")));
const Refer = lazy(() => lazyRetry(() => import("../pages/refer/Refer")));
const Settings = lazy(() => lazyRetry(() => import("../pages/settings")));
const ChangePassword = lazy(() =>
  lazyRetry(() => import("../pages/settings/ChangePassword")),
);
const MfaSetup = lazy(() =>
  lazyRetry(() => import("../pages/settings/mfa/MfaSetup")),
);
const Sip = lazy(() => lazyRetry(() => import("../pages/sip")));
const TransactionTable = lazy(() =>
  lazyRetry(() => import("../pages/transaction")),
);
const Wallet = lazy(() => lazyRetry(() => import("../pages/wallet")));
const Deposit = lazy(() => lazyRetry(() => import("../pages/wallet/Deposit")));
const Withdraw = lazy(() =>
  lazyRetry(() => import("../pages/wallet/Withdraw")),
);
const WithdrawStatus = lazy(() =>
  lazyRetry(() => import("../pages/wallet/WithdrawStatus")),
);
const WithdrawVerification = lazy(() =>
  lazyRetry(() => import("../pages/wallet/WithdrawVerification")),
);
const PrivateRoute = lazy(() =>
  lazyRetry(() => import("../routes/PrivateRoute")),
);
const FaqQnaCard = lazy(() => lazyRetry(() => import("./FaqQnaCard")));
const FaqSection = lazy(() => lazyRetry(() => import("./FaqSection")));
const LoginRoute = lazy(() => lazyRetry(() => import("./LoginRoute")));
const SignUpRoute = lazy(() => lazyRetry(() => import("./SignUpRoute")));

function CodeSplittingFallback() {
  const { user } = useAuth();
  if (user) {
    return (
      <Layout>
        <Loader />
      </Layout>
    );
  } else {
    return <LoadingFallback />;
  }
}

function NordlBrowserRouter() {
  return (
    <BrowserRouter>
      <Suspense fallback={<CodeSplittingFallback />}>
        <Routes>
          <Route path="/*" element={<LoginRoute />} />
          <Route path="/sign-up/*" element={<SignUpRoute />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password">
            <Route index={true} element={<ResetPasswordVerification />} />
            <Route path="new" element={<ResetPassword />} />
            <Route path="success" element={<ResetPasswordSucess />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/portfolio">
              <Route index={true} element={<Portfolio />} />
              <Route path="orders" element={<OrdersPage />} />
            </Route>
            <Route path="/wallet">
              <Route index={true} element={<Wallet />} />
              <Route path="deposit" element={<Deposit />} />
              <Route path="withdraw" element={<Withdraw />} />
              <Route
                path="withdraw-verification"
                element={<WithdrawVerification />}
              />
              <Route path="withdraw-status" element={<WithdrawStatus />} />
              <Route path="transactions" element={<TransactionTable />} />
            </Route>
            <Route path="/refer-a-friend" element={<Refer />} />
            <Route path="/settings">
              <Route index={true} element={<Settings />} />
              <Route path="change-password" element={<ChangePassword />} />
              <Route path="mfa">
                <Route path="enable/setup" element={<MfaSetup />} />
                <Route path="modify/setup" element={<MfaSetup />} />
              </Route>
            </Route>
            <Route path="/invest" element={<Invest />} />
            <Route path="/dca">
              <Route index={true} element={<Dca />} />
              <Route path="all-dcas" element={<AllDcas />} />
            </Route>
            <Route path="/sip" element={<Sip />} />
          </Route>
          <Route
            path="/invest/details/:poolName/:poolId"
            element={<InvestDetails />}
          />
          <Route
            path="/dca/details/:dcaAssetName/:dcaAssetId"
            element={<DcaDetails />}
          />
          <Route path="/help">
            <Route path="faq" element={<Faq />}>
              <Route path=":section" element={<FaqSection />} />
              <Route
                path=":section/:question/:questionId"
                element={<FaqQnaCard />}
              />
            </Route>
          </Route>
          {/* NOTE: should be the last route */}
          <Route path="*" element={<Error404 />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default NordlBrowserRouter;
