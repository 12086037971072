import axios from "axios";
import { nordNxtApiEndpoint } from "../../config/config";
import getAuthHeader from "../../utils/getAuthHeader";

async function requestKycStatus(userId: string) {
  const { data } = await axios.get(
    `${nordNxtApiEndpoint}/request-kyc-status/${userId}`,
    getAuthHeader(),
  );
  return data;
}

export default requestKycStatus;
