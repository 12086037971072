import React, { createContext, useContext, useReducer, useState } from "react";
import { User } from "../config/types";
import LocalStorageService from "../services/LocalStorageService";
import userReducer from "../services/reducers/userReducer";
import { func } from "prop-types";

type AuthContextType = {
  user: User | null;
  userDispatch: React.Dispatch<any>;
  calcOpen: any;
  setCalOpen: any;
};

const AuthContext = createContext<AuthContextType>({
  user: null,
  userDispatch: ({ type, payload }: { type: string; payload: any }) => {},
  calcOpen: null,
  setCalOpen: func,
});

export function AuthProvider({ children }: { children: JSX.Element }) {
  let initialUser = null;
  if (LocalStorageService?.getItem("user")) {
    initialUser = LocalStorageService.getItem("user");
  }
  const [user, userDispatch] = useReducer(userReducer, initialUser);
  const [calcOpen, setCalOpen] = useState(false);

  return (
    <AuthContext.Provider value={{ user, userDispatch, calcOpen, setCalOpen }}>
      {children}
    </AuthContext.Provider>
  );
}

export default function useAuth() {
  return useContext(AuthContext);
}
