import { useQuery } from "@tanstack/react-query";
import requestKycStatus from "../api/kyc/requestKycStatus";
import { kycStatuses } from "../config/config";
import useAuth from "../contexts/AuthProvider";

export default function useKycStatus() {
  const { user } = useAuth();
  const { data, isFetching, isLoading, isError, isSuccess } = useQuery({
    queryKey: ["kyc-status", user?.userId],
    queryFn: () => requestKycStatus(user?.userId || ""),
    staleTime: Infinity,
    retry: false,
    enabled: user !== null,
  });

  const isKycDone = data?.data?.kycStatus === kycStatuses.successful;

  return {
    data: data?.data,
    isKycDone,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  };
}
