import axios from "axios";
import { nordNxtApiEndpoint } from "../../config/config";
import getAuthHeader from "../../utils/getAuthHeader";

export default async function markNotificationAsRead(notificationId: string) {
  const { data } = await axios.post(
    `${nordNxtApiEndpoint}/notification/mark-read`,
    { notificationId: notificationId },
    getAuthHeader(),
  );
  return data;
}
