import React, { memo } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import AssetPrices from "./AssetPrices";
import {
  gaEventCats,
  mfaFaqLink,
  nordlStartInvestProgram,
  referralBonusFaqLink,
  timerComponentKeys,
} from "../config/config";
import useTimer from "../hooks/useTimer";
import useAuth from "../contexts/AuthProvider";
import useIsJoiningBonusReceived from "../hooks/useIsJoiningBonusReceived";
import sendGaEvent from "../services/sendGaEvent";
import useScreenResizeListener from "../hooks/useScreenResizeListener";

const sendCarouselGaEvent = (url) =>
  sendGaEvent(gaEventCats.navbar, "FromCarousel", url);

function FirstInvestmentSlide() {
  return (
    <div key="joining" className="">
      <h4 className="font-bold">
        Get <strong className="text-nordColor8">$5</strong> in Bitcoin!
      </h4>
      <div>
        Complete your&nbsp;
        <Link
          to={nordlStartInvestProgram}
          className="text-nordColor5 underline"
          onClick={() => sendCarouselGaEvent(nordlStartInvestProgram)}
        >
          first investment
        </Link>
        &nbsp;and receive BTC.
      </div>
    </div>
  );
}

function ReferralBonusSlide() {
  return (
    <div key="referral" className="">
      <h4 className="font-bold">
        Earn a <strong className="text-nordColor8">$5</strong> Referral Bonus
        Today!
      </h4>
      <div>
        <Link
          to={referralBonusFaqLink}
          className="text-nordColor5 underline"
          onClick={() => sendCarouselGaEvent(referralBonusFaqLink)}
        >
          Refer your friend
        </Link>
        &nbsp;and earn referral rewards.
      </div>
    </div>
  );
}

function MfaSlide() {
  return (
    <div key="mfa" className="">
      <h4 className="font-bold">Keep Your Account Secure</h4>
      <div>
        Enable MFA Today.&nbsp;
        <Link
          to={mfaFaqLink}
          className="text-nordColor5 underline"
          onClick={() => sendCarouselGaEvent(mfaFaqLink)}
        >
          Check Steps.
        </Link>
      </div>
    </div>
  );
}

function AssetPricesSlide() {
  return (
    <div key="assetPrices" className="pt-2">
      <AssetPrices />
    </div>
  );
}

function CarouselWrapper({ children }) {
  const { isScreenWidthSmallerThan1024 } = useScreenResizeListener();
  const { user } = useAuth();

  if (isScreenWidthSmallerThan1024 || !user) {
    return null;
  } else {
    return (
      <Carousel
        showStatus={false}
        showArrows={false}
        showIndicators={false}
        showThumbs={false}
        infiniteLoop={true}
        autoPlay={true}
        // milliseconds
        interval={5000}
        className="max-h-fit text-center font-dm text-sm lg:max-w-xl xl:max-w-2xl 2xl:max-w-3xl"
      >
        {children}
      </Carousel>
    );
  }
}

function NoticeCarousel() {
  const { secondsRemaining } = useTimer(
    // seconds
    300,
    timerComponentKeys.noticeCarousel,
    true,
  );

  const { isScreenWidthSmallerThan1024 } = useScreenResizeListener();
  const { user } = useAuth();
  const { isJoiningBonusReceived } = useIsJoiningBonusReceived();
  const isMfaEnabled = user?.isMfaEnabled;

  if (secondsRemaining > 0) {
    if (isJoiningBonusReceived && isMfaEnabled) {
      return (
        <CarouselWrapper>
          <ReferralBonusSlide />
          <AssetPricesSlide />
        </CarouselWrapper>
      );
    } else if (isJoiningBonusReceived) {
      return (
        <CarouselWrapper>
          <ReferralBonusSlide />
          <MfaSlide />
          <AssetPricesSlide />
        </CarouselWrapper>
      );
    } else if (isMfaEnabled) {
      return (
        <CarouselWrapper>
          <FirstInvestmentSlide />
          <ReferralBonusSlide />
          <AssetPricesSlide />
        </CarouselWrapper>
      );
    } else {
      return (
        <CarouselWrapper>
          <FirstInvestmentSlide />
          <ReferralBonusSlide />
          <MfaSlide />
          <AssetPricesSlide />
        </CarouselWrapper>
      );
    }
  } else {
    if (isScreenWidthSmallerThan1024 || !user) {
      return null;
    } else {
      return (
        <Carousel
          showStatus={false}
          showArrows={false}
          showIndicators={false}
          showThumbs={false}
          infiniteLoop={true}
          className="max-h-fit text-center font-dm text-sm lg:max-w-xl xl:max-w-2xl 2xl:max-w-3xl"
          selectedItem={1}
        >
          <AssetPricesSlide />
        </Carousel>
      );
    }
  }
}

export default memo(NoticeCarousel);
