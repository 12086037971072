import { Cookies } from "react-cookie";
import { cookieNames } from "../config/cookieNames";

function getAuthHeader() {
  const cookies = new Cookies();
  return {
    headers: {
      Authorization: `Bearer ${cookies.get(cookieNames.accessToken)}`,
    },
  };
}

export default getAuthHeader;
