import reactGa from "react-ga4";

function sendGaEvent(category: string, action: string, label?: string) {
  if (label === undefined) {
    reactGa.event({
      category,
      action,
    });
  } else {
    reactGa.event({
      category,
      action,
      label,
    });
  }
}

export default sendGaEvent;
