import React from "react";
import PageNotFound from "../../assets/images/page_not_found.svg";
import Icon404 from "../../assets/images/404_icon.svg";
import Logo from "../../assets/images/logo.svg";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Error404() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-center w-[100vw] h-[100vh] login-bg">
      <div className="bg-white rounded-[100%] shadow-card flex flex-col items-center justify-center w-96 h-96 lg:w-[44vw] lg:h-[44vw] ">
        <img className="w-1/4 mb-8" src={Logo} alt="status code 404 icon" />
        <img className="w-1/2 mb-5" src={Icon404} alt="status code 404 icon" />
        <img className="w-1/2 mb-8" src={PageNotFound} alt="page not found" />
        <Button
          variant={"darkBtn"}
          btnStyles={{ backgroundColor: "#304FFE" }}
          onClick={() => {
            navigate("/");
          }}
        >
          {t("goBackToHomePage")}
        </Button>
      </div>
    </div>
  );
}

export default Error404;
