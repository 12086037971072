import React from "react";
import loader from "../../assets/images/loader.svg";
import Logo from "../../assets/images/logo.svg";

function LoadingFallback() {
  return (
    <div className="flex items-center justify-center w-[100vw] h-[100vh] login-bg">
      <div className="bg-white rounded-[100%] shadow-card flex flex-col items-center justify-center w-96 h-96 lg:w-[44vw] lg:h-[44vw] ">
        <img className="w-1/4 mb-8" src={Logo} alt="status code 404 icon" />
        <img
          className="w-1/2 mb-5"
          src={loader}
          alt="loader"
          style={{ width: "64px", height: "64px" }}
        />
      </div>
    </div>
  );
}

export default LoadingFallback;
