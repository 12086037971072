import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import sendGaEvent from "../../services/sendGaEvent";
import { gaEventCats } from "../../config/config";
import {
  DropdownIcon,
  PortfolioActiveIcon,
  PortfolioIcon,
} from "../../foundations/icon";
import SidebarItemText from "./SidebarItemText";
import getSubItemStyle from "../../utils/getSubItemStyle";
import useHandleNavlinkClick from "../../hooks/useHandleNavlinkClick";
import useUserSettings from "../../contexts/UserSettingsProvider";

function PortfolioNavLink({
  isEachPortfolioItemVisible,
  toggleIsEachPortfolioItemVisible,
}) {
  const { t } = useTranslation();
  const { handleNavlinkClick } = useHandleNavlinkClick(gaEventCats.sidebar);
  const { isSidebarOpen } = useUserSettings();

  return (
    <>
      <div
        className={`flex items-center mt-8 ${
          !isSidebarOpen ? "justify-center" : "justify-between"
        }`}
      >
        <NavLink
          to="/portfolio"
          className="flex items-center cursor-pointer w-full"
          onClick={() => sendGaEvent(gaEventCats.sidebar, "NavToPortfolio")}
        >
          {({ isActive }) => (
            <>
              <div
                className={`  ${
                  isSidebarOpen ? "2xl:mr-4 mr-2" : "w-full flex justify-center"
                } cursor-pointer `}
              >
                {isActive ? <PortfolioActiveIcon /> : <PortfolioIcon />}
              </div>
              <SidebarItemText
                isActive={isActive}
                text="portfolio"
                isSidebarOpen={isSidebarOpen}
              />
            </>
          )}
        </NavLink>
        {isSidebarOpen ? (
          <div
            className="self-center cursor-pointer"
            onClick={toggleIsEachPortfolioItemVisible}
          >
            <DropdownIcon />
          </div>
        ) : null}
      </div>
      {isSidebarOpen && isEachPortfolioItemVisible ? (
        <>
          <div className="flex flex-col gap-3 pt-1">
            <NavLink
              to="/portfolio/orders"
              className={({ isActive }) => getSubItemStyle(isActive)}
              onClick={() => handleNavlinkClick("NavToOrders")}
            >
              {t("orders.orders")}
            </NavLink>
          </div>
        </>
      ) : null}
    </>
  );
}

PortfolioNavLink.propTypes = {
  isEachPortfolioItemVisible: PropTypes.bool.isRequired,
  toggleIsEachPortfolioItemVisible: PropTypes.func.isRequired,
};

export default PortfolioNavLink;
