import React, { useEffect } from "react";
import cancel from "../../assets/images/cancel.svg";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import markNotificationAsRead from "../../api/pushnotification/markNotificationAsRead";
import { LinkCheckregex } from "../../config/config";
import { ModalCloseIcon } from "../../foundations/icon";
import Modal from "react-modal";
import { showError } from "../Toast/Toast";
import useAuth from "../../contexts/AuthProvider";

const NotificationDropDownList = ({
  dropDown,
  notifications,
  setDropdown,
  refetching,
}) => {
  // React query: mutation for post request
  const navigate = useNavigate();
  const regex = new RegExp(LinkCheckregex);
  const { setCalOpen } = useAuth();
  const { mutate } = useMutation(markNotificationAsRead, {
    onSuccess: () => {
      refetching();
    },
    onError: (error) => {
      showError(error);
    },
  });
  useEffect(() => {
    const interval = setInterval(() => {
      setDropdown(false);
    }, 15000);

    return () => clearInterval(interval);
  }, [dropDown, setDropdown]);

  // handling the remove item.
  const handleItemClick = (itemId) => {
    // Filter out the clicked item based on its ID
    mutate(itemId);
  };

  //handling the redirect item
  const handelRedirectClick = (itemURL, itemId) => {
    mutate(itemId);
    if (regex.test(itemURL)) {
      window.open(itemURL, "_blank", "noopener,noreferrer");
    } else if (itemURL === "/calculator") {
      navigate("/dashboard");
      setCalOpen(true);
    } else {
      navigate(itemURL);
    }

    setDropdown(false);
  };

  return (
    <Modal
      overlayClassName="bg-black/50 fixed  top-0 left-0 right-0 bottom-0"
      isOpen={dropDown}
      appElement={document.getElementById("modal-container") || undefined}
      className="w-full h-full flex justify-center items-center"
    >
      <div className="relative mx-3 md:px-9 px-5 py-9 bg-white rounded-[30px] flex flex-col font-dm lg:mx-0 xl:w-[25%] lg:w-[35%] md:w-[40%] sm:w-[45%] w-[85%]">
        <div
          className="absolute top-2 right-2 cursor-pointer"
          onClick={() => {
            setDropdown((prevDropdown) => !prevDropdown);
          }}
        >
          <ModalCloseIcon />
        </div>
        <p className="text-nordColor2 font-bold text-xl">Notification</p>
        <div className="overflow-y-auto h-64">
          {notifications?.map((item, index) => {
            return item.notificationText !== null ? (
              <div
                className="flex flex-row justify-between items-center border-1 shadow-sm gap-3"
                key={index}
              >
                <h4
                  className="text-nordColor1 text-sm font-medium py-5 cursor-pointer"
                  onClick={() => {
                    handelRedirectClick(
                      item?.notification.notificationURL,
                      item.id,
                    );
                  }}
                >
                  {item.notificationText}
                </h4>
                <img
                  src={cancel}
                  alt="cancel"
                  className="w-6 cursor-pointer "
                  onClick={() => handleItemClick(item.id)}
                />
              </div>
            ) : null;
          })}
        </div>
      </div>
    </Modal>
  );
};

export default NotificationDropDownList;
