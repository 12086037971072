import React, { useState } from "react";
import LanguageDropdown from "../LanguageDropdown";
import ProfilePicture from "../../assets/images/profile-picture.png";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../contexts/AuthProvider";
import clearDataGotoLogin from "../../services/clearDataGotoLogin";
import { logOutSuccess } from "../../config/toastMessages";
import sendGaEvent from "../../services/sendGaEvent";
import {
  gaEventCats,
  playstoreLink,
  tokenInvalidErrCode,
} from "../../config/config";
import { useTranslation } from "react-i18next";
import NoticeCarousel from "../NoticeCarousel";
import useUserSettings from "../../contexts/UserSettingsProvider";
import { NavbarBellIcon, OpenSidebarIcon } from "../../foundations/icon";
import Logo from "../../assets/images/logo.svg";
import { useMutation } from "@tanstack/react-query";
import signOut from "../../api/auth/signOut";
import useGetNotifications from "../../hooks/pushNotification/useGetNotifications";
import NotificationDropDownList from "../PushNotification/NotificationDropDownList";

function NordlLogo() {
  const { user } = useAuth();
  return (
    <Link
      className={`w-44 ${user ? "ml-12 lg:m-0 lg:hidden" : ""}`}
      to={user ? "/dashboard" : "/"}
    >
      <img src={Logo} alt="norDL logo" />
    </Link>
  );
}

function Navbar() {
  const navigate = useNavigate();
  const [dropdown, setDropdown] = useState(false);
  const [notidropdown, setNotiDropdown] = useState(false);
  const { user, userDispatch } = useAuth();
  const { data, refetch } = useGetNotifications();
  const { t } = useTranslation();
  const { isSidebarOpen, toggleSidebar } = useUserSettings();

  const { firstName, lastName } = user ? user : { firstName: "", lastName: "" };
  const logOutMutation = useMutation(signOut, {
    onSuccess: () => {
      clearDataGotoLogin(navigate, userDispatch, logOutSuccess);
      sendGaEvent(gaEventCats.navbar, "SignOut", user.userId.toString());
    },
    onError: (error) => {
      if (error.response.data.code === tokenInvalidErrCode) {
        clearDataGotoLogin(navigate, userDispatch, logOutSuccess);
      }
    },
  });

  return (
    <div className="relative font-dm flex justify-between px-8 xl:px-10 border-b border-nordColor10 items-center bg-nordColor12">
      {/* Open sidebar button */}
      {!isSidebarOpen && user !== null ? (
        <button
          className="open-sidebar-btn absolute cursor-pointer lg:-left-0 lg:top-16"
          onClick={() => {
            toggleSidebar();
            sendGaEvent(gaEventCats.sidebar, "OpenSidebar");
          }}
        >
          <OpenSidebarIcon />
        </button>
      ) : null}

      {/* NOTE: below div added for positiong of NoticeCarousel to be in center */}
      {user ? <div></div> : null}
      <NoticeCarousel />
      <NordlLogo />
      <div className="py-3.5 flex gap-4 items-center">
        <LanguageDropdown position={"bottom"} />
        {user !== null ? (
          <div className="relative cursor-pointer">
            {/* NOTE: hidden until notification feature release */}
            <div
              onClick={() => {
                setNotiDropdown(true);
                refetch();
              }}
            >
              <NavbarBellIcon />
            </div>
            {data !== undefined && Array.isArray(data.data) && (
              <div
                className={
                  data.data.length > 0
                    ? "bg-nordColor14 w-2 h-2 rounded-[50%] absolute top-0 right-1"
                    : null
                }
              ></div>
            )}
            {data !== undefined && Array.isArray(data.data) && (
              <NotificationDropDownList
                setDropdown={setNotiDropdown}
                dropDown={notidropdown}
                notifications={data.data}
                refetching={refetch}
              />
            )}
          </div>
        ) : null}

        <button
          className="flex items-center gap-2.5 cursor-pointer relative"
          onClick={() => {
            setDropdown((prevDropdown) => !prevDropdown);
          }}
          disabled={user === null}
        >
          <img
            src={ProfilePicture}
            alt="User profile"
            className="w-[2.25rem]"
          />
          <p className="hidden text-sm font-medium text-nordColor1 lg:block">{`${firstName} ${lastName}`}</p>
          <div
            className={
              dropdown
                ? "absolute w-[146px] divide-y divide-y-nordColor10 bg-white top-[60px] right-[0px] rounded-lg z-10"
                : "hidden"
            }
          >
            <h4
              className="text-nordColor1 text-sm font-medium px-4 py-5"
              onClick={(e) => {
                e.stopPropagation();
                setDropdown(false);
                navigate("/settings");
                sendGaEvent(gaEventCats.navbar, "NavToSettings");
              }}
            >
              {t("settings.heading")}
            </h4>
            <h4
              className="text-nordColor1 text-sm font-medium px-4 py-5"
              onClick={(e) => {
                e.stopPropagation();
                setDropdown(false);
                console.log(dropdown);
                navigate("/refer-a-friend");
                sendGaEvent(gaEventCats.navbar, "NavToRefer");
              }}
            >
              {t("referPage.referFriend")}
            </h4>
            <h4
              className="text-nordColor1 text-sm font-medium px-4 py-5"
              onClick={(e) => {
                e.stopPropagation();
                setDropdown(false);
                window.open(playstoreLink);
                sendGaEvent(gaEventCats.installCta, "NavToPlaystore");
              }}
            >
              {t("cta.installApp")}
            </h4>
            <h4
              className="text-nordColor8 text-sm font-semibold p-4"
              onClick={(e) => {
                e.stopPropagation();
                logOutMutation.mutate();
              }}
            >
              {t("auth.signOut")}
            </h4>
          </div>
        </button>
      </div>
    </div>
  );
}

export default Navbar;
