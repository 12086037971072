import React, { useRef } from "react";
import CookieConsentOverlay from "./components/CookieConsentOverlay";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { tawkToPropertyId, tawkToWidgetId } from "./config/config";
import useAuth from "./contexts/AuthProvider";
import useUserSettings from "./contexts/UserSettingsProvider";
import Toast from "./components/Toast/Toast";
import NordlBrowserRouter from "./components/NordlBrowserRouter";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import useAuthQueryClient from "./hooks/App/useAuthQueryClient";
import AddToHomescreen from "./components/AddToHomescreen";
import OfflineLayout from "./components/OfflineLayout";
import Notifications from "./components/PushNotification/Notifications";
import { onMessageListener } from "./hooks/pushNotification/firebaseInit";

function App() {
  const { user } = useAuth();
  const queryClient = useAuthQueryClient();
  const tawkMessengerRef = useRef(null);
  const { isConsentCookieSet, attachTawkMessengerRef } = useUserSettings();
  const hideTawkToWidget = () => tawkMessengerRef.current?.hideWidget();
  const onLoad = () => {
    if (user !== null) {
      hideTawkToWidget();
      tawkMessengerRef.current?.setAttributes({
        name: user?.firstName || "",
        email: user?.email || "",
      });
      attachTawkMessengerRef(tawkMessengerRef);
    }
  };
  const onChatMinimized = () => hideTawkToWidget();

  const isTawkToMountable = user !== null && isConsentCookieSet;

  onMessageListener()
    .then((payload) => {
      return payload;
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <>
      <Notifications />

      <OfflineLayout>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <NordlBrowserRouter />
        </QueryClientProvider>
        <AddToHomescreen />
        <Toast />
        <CookieConsentOverlay />
        {isTawkToMountable ? (
          <TawkMessengerReact
            propertyId={tawkToPropertyId}
            widgetId={tawkToWidgetId}
            onLoad={onLoad}
            onChatMinimized={onChatMinimized}
            ref={tawkMessengerRef}
          />
        ) : null}
      </OfflineLayout>
    </>
  );
}

export default App;
