import React from "react";
import Button from "./Button/Button";
import Logo from "../assets/images/logo.svg";

function Fallback() {
  return (
    <div className="flex items-center justify-center w-[100vw] h-[100vh] login-bg">
      <div className="bg-white rounded-[100%] shadow-card flex flex-col items-center justify-center w-96 h-96 gap-8 lg:w-[44vw] lg:h-[44vw]">
        <img className="w-2/4 lg:w-1/4" src={Logo} alt="norDL logo" />
        <h1 className="font-extrabold text-center text-xl text-nordColor2 lg:text-3xl">
          Oops! Something went wrong.
        </h1>
        <Button
          variant={"darkBtn"}
          btnStyles={{ backgroundColor: "#304FFE" }}
          onClick={() => window.location.reload()}
        >
          Reload
        </Button>
      </div>
    </div>
  );
}

export default Fallback;
