import { useEffect, useState } from "react";
// import { AES } from "crypto-js";
import useAuth from "../../contexts/AuthProvider";
// import { getTokens } from "../../hooks/pushNotification/firebaseInit";
import pushToken from "../../api/pushnotification/postToken";
// import { dataSecretKey } from "../../config/config";
import LocalStorageService from "../../services/LocalStorageService";
import { useCallback } from "react";
import OneSignal from "react-onesignal";

const Notifications = (props) => {
  const { user } = useAuth();
  const [isNotificationAllowed, setNotificationAllowed] = useState(false);

  const requestPermission = useCallback(() => {
    // Logic for requesting permission
    if (LocalStorageService?.getItem("notification")) {
      setNotificationAllowed(LocalStorageService.getItem("notification"));
    } else {
      (async () => {
        // const permission = await Notification.requestPermission();
        if (!isNotificationAllowed) {
          // const token = await getTokens();
          localStorage.setItem("notification", true);

          // const encryptedData = AES.encrypt(token, dataSecretKey).toString();
          OneSignal.init({
            appId: "10145a11-a170-4c1b-8c56-10b7f71b9000",
          }).then(() => {
            OneSignal.login(user.userId)
            OneSignal.User.addEmail(user.email);
          });
          await pushToken(user.userId);
        }
      })();
    }
  }, [isNotificationAllowed, user]);

  useEffect(() => {
    if (user !== null) {
      requestPermission();
    }
  }, [user, requestPermission]);
  return <></>;
};
export default Notifications;
