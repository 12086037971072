import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import getOnboardingStatus from "../../api/onboarding/getOnboardingStatus";
import { nextTourOptions } from "../../config/config";
import useAuth from "../../contexts/AuthProvider";

export default function useOnboardingStatus() {
  const { user } = useAuth();

  const userOnboardingStatus = useQuery({
    queryKey: ["onboarding-status"],
    queryFn: getOnboardingStatus,
    onError: (error: any) => {},
    enabled: user !== null,
    staleTime: Infinity,
  });

  const nextTourToBeShown = userOnboardingStatus?.data?.data?.nextTourToBeShown;

  const { isPreKycTourComplete, isPostKycTourComplete, isDcaTourComplete } =
    useMemo(() => {
      const isPreKycTourComplete = nextTourOptions
        .slice(1, nextTourOptions.length)
        .includes(nextTourToBeShown);
      const isPostKycTourComplete = nextTourOptions
        .slice(2, nextTourOptions.length)
        .includes(nextTourToBeShown);
      const isDcaTourComplete = nextTourToBeShown === null;

      return { isPreKycTourComplete, isPostKycTourComplete, isDcaTourComplete };
    }, [nextTourToBeShown]);

  return {
    userOnboardingStatus,
    nextTourToBeShown,
    isPreKycTourComplete,
    isPostKycTourComplete,
    isDcaTourComplete,
  };
}
