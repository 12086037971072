export const preKycGotoNextSection = (
  navigate: (targetPath: string) => void,
  pathname: string,
) => {
  switch (pathname) {
    case "/dashboard":
      break;
    case "/invest":
      navigate("/dca");
      break;
    case "/dca":
      navigate("/wallet");
      break;
    case "/wallet":
      navigate("/dashboard");
      break;
    default:
      break;
  }
};

export const postKycGotoNextSection = (
  navigate: (targetPath: string) => void,
  pathname: string,
) => {
  switch (pathname) {
    case "/dashboard":
      break;
    case "/wallet":
      break;
    case "/wallet/deposit":
      navigate("/wallet/withdraw");
      break;
    case "/wallet/withdraw":
      navigate("/wallet/transactions");
      break;
    case "/wallet/transactions":
      navigate("/portfolio");
      break;
    case "/portfolio":
      navigate("/refer-a-friend");
      break;
    default:
      break;
  }
};

export const dcaGotoNextSection = (
  navigate: (targetPath: string) => void,
  pathname: string,
) => {
  switch (pathname) {
    case "/dashboard":
      navigate("/dca");
      break;
    case "/dca":
      navigate("/dca/details/bitcoin/2");
      break;
    case "/dca/details/bitcoin/2":
      break;
    default:
      break;
  }
};
