import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import PropTypes from "prop-types";
import useUserSettings from "../../contexts/UserSettingsProvider";
import useScreenResizeListener from "../../hooks/useScreenResizeListener";
import useOnboardingTour from "../../hooks/OnboardingTour/useOnboardingTour";

function Layout(props) {
  const { isScreenWidthSmallerThan1024 } = useScreenResizeListener();
  const { isSidebarOpen, toggleSidebar } = useUserSettings();
  const { run } = useOnboardingTour();
  const handleOutsideSidebarClick = () => {
    // NOTE: this function is triggered when clicked on next in onboarding tour
    isScreenWidthSmallerThan1024 && isSidebarOpen && !run && toggleSidebar();
  };

  return (
    <div className={"layout h-full flex fixed w-full"}>
      <Sidebar />
      <div className="flex flex-col w-full bg-nordColor10">
        <Navbar />
        <div
          className="overflow-y-auto px-8 py-4 overflow-x-auto xl:px-14 xl:py-8"
          onClick={handleOutsideSidebarClick}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.any,
};

export default Layout;
