import { useEffect, useState } from "react";
import { savedSeconds } from "../config/config";
import SessionStorageService from "../services/SessionStorageService";

// NOTE: setInterval is not accurate

export default function useTimer(
  countDownInSeconds: number,
  componentKey: string,
  isTimerToRun = true,
  isTimerToBeCached = true,
) {
  const sessionStorageKey = `${savedSeconds}-${componentKey}`;
  const savedSecondsRemaining =
    SessionStorageService.getItem(sessionStorageKey);

  const initialSecondsRemaining =
    Number(savedSecondsRemaining) || countDownInSeconds;

  const [secondsRemaining, setSecondsRemaining] = useState<number>(
    initialSecondsRemaining,
  );
  if (isTimerToBeCached) {
    SessionStorageService.setItem(
      sessionStorageKey,
      secondsRemaining.toString(),
    );
  }

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (secondsRemaining > 0 && isTimerToRun) {
      timer = setInterval(() => {
        setSecondsRemaining((prevSecondsRemaining) => prevSecondsRemaining - 1);
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [isTimerToRun, secondsRemaining]);

  useEffect(() => {
    setSecondsRemaining(countDownInSeconds);
  }, [countDownInSeconds]);

  function restartTimer() {
    setSecondsRemaining(countDownInSeconds);
  }

  return { secondsRemaining, restartTimer };
}

export function useTimerClient() {
  const resetSavedTimer = (componentKey: string) => {
    window.sessionStorage.removeItem(`${savedSeconds}-${componentKey}`);
  };

  return { resetSavedTimer };
}
