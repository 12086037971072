import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import sendGaEvent from "../../services/sendGaEvent";
import { contactUsUrl, gaEventCats } from "../../config/config";
import SidebarItemText from "./SidebarItemText";
import { DropdownIcon, HelpActiveIcon, HelpIcon } from "../../foundations/icon";
import getSubItemStyle from "../../utils/getSubItemStyle";
import useHandleNavlinkClick from "../../hooks/useHandleNavlinkClick";
import useUserSettings from "../../contexts/UserSettingsProvider";

function HelpNavLink({ isEachHelpItemVisible, toggleIsEachHelpItemVisible }) {
  const { t } = useTranslation();
  const { handleNavlinkClick } = useHandleNavlinkClick(gaEventCats.sidebar);
  const { isSidebarOpen, tawkMessengerRef } = useUserSettings();

  const handleChatClick = () => {
    tawkMessengerRef.current?.showWidget();
    tawkMessengerRef.current?.maximize();
  };

  return (
    <>
      <div
        className={`flex items-center mt-8 ${
          !isSidebarOpen ? "justify-center" : "justify-between"
        }`}
      >
        <NavLink
          to="/help/faq"
          className="flex items-center cursor-pointer w-full"
          onClick={() => sendGaEvent(gaEventCats.sidebar, "NavToHelp")}
        >
          {({ isActive }) => (
            <>
              <div
                className={`${
                  isSidebarOpen ? "2xl:mr-4 mr-2" : "w-full flex justify-center"
                } cursor-pointer`}
              >
                {isActive ? <HelpActiveIcon /> : <HelpIcon />}
              </div>
              <SidebarItemText
                isActive={isActive}
                text="help"
                isSidebarOpen={isSidebarOpen}
              />
            </>
          )}
        </NavLink>
        {isSidebarOpen ? (
          <div
            className="self-center cursor-pointer"
            onClick={toggleIsEachHelpItemVisible}
          >
            <DropdownIcon />
          </div>
        ) : null}
      </div>
      {isSidebarOpen && isEachHelpItemVisible ? (
        <>
          <div className="flex flex-col gap-3 pt-1">
            <NavLink
              to="/help/faq"
              className={({ isActive }) => getSubItemStyle(isActive)}
              onClick={() => handleNavlinkClick("NavToFaq")}
            >
              {t("faq")}
            </NavLink>
            <a
              href={contactUsUrl}
              className={getSubItemStyle(false)}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleNavlinkClick("NavToContactUs")}
            >
              {t("contactUs")}
            </a>
            <button
              className={`${getSubItemStyle(false)} text-left`}
              onClick={handleChatClick}
            >
              {t("chat")}
            </button>
          </div>
        </>
      ) : null}
    </>
  );
}

HelpNavLink.propTypes = {
  isEachHelpItemVisible: PropTypes.bool.isRequired,
  toggleIsEachHelpItemVisible: PropTypes.func.isRequired,
};

export default HelpNavLink;
