import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { gaEventCats } from "../../config/config";
import useUserSettings from "../../contexts/UserSettingsProvider";
import {
  DcaActiveIcon,
  DcaInactiveIcon,
  DropdownIcon,
} from "../../foundations/icon";
import useHandleNavlinkClick from "../../hooks/useHandleNavlinkClick";
import sendGaEvent from "../../services/sendGaEvent";
import getSubItemStyle from "../../utils/getSubItemStyle";
import SidebarItemText from "./SidebarItemText";

function DcaNavLink({ isEachDcaItemVisible, toggleIsEachDcaItemVisible }) {
  const { t } = useTranslation();
  const { handleNavlinkClick } = useHandleNavlinkClick(gaEventCats.sidebar);
  const { isSidebarOpen } = useUserSettings();

  return (
    <>
      <div
        className={`flex items-center mt-8 ${
          !isSidebarOpen ? "justify-center" : "justify-between"
        }`}
      >
        <NavLink
          to="/dca"
          className="crypto-dca flex items-center cursor-pointer w-full"
          onClick={() => sendGaEvent(gaEventCats.sidebar, "NavToDca")}
        >
          {({ isActive }) => (
            <>
              <div
                className={`  ${
                  isSidebarOpen ? "2xl:mr-4 mr-2" : "w-full flex justify-center"
                } cursor-pointer `}
              >
                {isActive ? <DcaActiveIcon /> : <DcaInactiveIcon />}
              </div>
              <SidebarItemText
                isActive={isActive}
                text="dca"
                isSidebarOpen={isSidebarOpen}
              />
            </>
          )}
        </NavLink>
        {isSidebarOpen ? (
          <div
            className="self-center cursor-pointer"
            onClick={toggleIsEachDcaItemVisible}
          >
            <DropdownIcon />
          </div>
        ) : null}
      </div>
      {isSidebarOpen && isEachDcaItemVisible ? (
        <>
          <div className="flex flex-col gap-3 pt-1">
            <NavLink
              to="/dca/all-dcas"
              className={({ isActive }) => getSubItemStyle(isActive)}
              onClick={() => handleNavlinkClick("NavToAllDcas")}
            >
              {t("allDcas")}
            </NavLink>
          </div>
        </>
      ) : null}
    </>
  );
}

DcaNavLink.propTypes = {
  isEachDcaItemVisible: PropTypes.bool.isRequired,
  toggleIsEachDcaItemVisible: PropTypes.func.isRequired,
};

export default DcaNavLink;
