import loader from "../assets/images/loader.svg";

export default function Loader() {
  return (
    <div className="flex flex-col h-full justify-center items-center">
      <div>
        <img
          src={loader}
          alt="loader"
          style={{ width: "64px", height: "64px" }}
        />
      </div>
    </div>
  );
}
