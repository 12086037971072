import React, { useEffect, useState } from "react";
import ShareIcon from "../assets/images/share_2.png";

const AddToHomescreen = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const hasSeenInstallPopup = localStorage.getItem("homescreenModalClosed");
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };
    const isInStandaloneMode = () =>
      "standalone" in window.navigator && window.navigator.standalone;

    if (!hasSeenInstallPopup && isIos() && !isInStandaloneMode()) {
      setShowModal(true);
    }
  }, []);

  const closeModal = () => {
    setShowModal(false);
    localStorage.setItem("homescreenModalClosed", "true");
  };

  if (showModal) {
    return (
      <div className="fixed bottom-0 left-0 right-0 bg-[#172683] text-white py-4 z-50">
        <div className="flex justify-end">
          <button className="mr-4 text-white" onClick={closeModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div class="flex flex-wrap items-center justify-center">
          <p class="text-center">
            For a better experience, add this app to your home screen. Tap:
          </p>
          <div class="flex items-center px-2">
            <img src={ShareIcon} alt="share" height={22} width={22} />
          </div>
          <p class="text-center">and then Add to home screen</p>
        </div>
      </div>
    );
  }

  return null;
};
export default AddToHomescreen;
