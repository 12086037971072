import { useQuery } from "@tanstack/react-query";
import getAllDcas from "../../api/dca/getAllDcaAssets";

export default function useAllDcaAssetsQuery(isEnabled = true) {
  return useQuery({
    queryKey: ["all-dca-assets"],
    queryFn: getAllDcas,
    staleTime: Infinity,
    enabled: isEnabled,
  });
}
