import { useQuery } from "@tanstack/react-query";
import getNotifications from "../../api/pushnotification/getNotification";

export default function useGetNotifications() {
  const { data, refetch } = useQuery(
    ["unread-notification"],
    getNotifications,
    {
      staleTime: Infinity,
    },
  );

  return { data, refetch };
}
