import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import "firebase/messaging";
import { firebaseConfig } from "../../config/firebaseConfig";
import { vapidKey } from "../../config/config";

const firebaseApp = initializeApp(firebaseConfig);
const messaging: any = getMessaging(firebaseApp);

export const getTokens = async () => {
  try {
    const token = await getToken(messaging, {
      vapidKey: vapidKey,
    });
    return token;
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload: any) => {
      resolve(payload);
    });
  });
