import { useLocation, useNavigate } from "react-router-dom";
import { ACTIONS, CallBackProps, EVENTS, STATUS } from "react-joyride";
import SessionStorageService from "../../services/SessionStorageService";
import { useEffect, useState } from "react";
import useKycStatus from "../useKycStatus";
import useScreenResizeListener from "../useScreenResizeListener";
import useUserSettings from "../../contexts/UserSettingsProvider";
import {
  dcaGotoNextSection,
  postKycGotoNextSection,
  preKycGotoNextSection,
} from "../../utils/OnboardingTour/gotoNextSection";
import useOnboardingStatus from "./useOnboardingStatus";
import useUpdateOnboardingStatus from "./useUpdateOnboardingStatus";
import useAllDcaAssetsQuery from "../dca/useAllDcaAssetsQuery";
import LocalStorageService from "../../services/LocalStorageService";
import dayjs from "dayjs";

export default function useOnboardingTour() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { isKycDone } = useKycStatus();
  const { isSidebarOpen, toggleSidebar } = useUserSettings();
  const { isScreenWidthSmallerThan1024 } = useScreenResizeListener();
  useAllDcaAssetsQuery();
  const isInitialDashboardTourComplete = SessionStorageService.getItem(
    "is-initial-dashboard-tour-complete",
  );
  const dcaTourSkipDate = LocalStorageService.getItem("dca-tour-skip-date");
  const dcaTourSkipCount = LocalStorageService.getItem("dca-tour-skip-count");

  const {
    userOnboardingStatus,
    nextTourToBeShown,
    isPreKycTourComplete,
    isPostKycTourComplete,
    isDcaTourComplete,
  } = useOnboardingStatus();

  const updateOnboardingStatusMutation = useUpdateOnboardingStatus();

  // TODO: move state to context, wrap NordlBrowserRouter in App.js
  const [{ run, stepIndex }, setTourState] = useState({
    run: false,
    stepIndex: 0,
  });

  const preKycHandleJoyrideCallback = ({
    action,
    index,
    status,
    type,
  }: CallBackProps) => {
    if (action === ACTIONS.CLOSE) {
      setTourState((prevTourState) => ({
        ...prevTourState,
        run: false,
        stepIndex: 0,
      }));
      if (status !== STATUS.PAUSED && status !== STATUS.FINISHED) {
        LocalStorageService.setItem(
          "dca-tour-skip-date",
          new Date().toISOString(),
        );
        LocalStorageService.setItem(
          "dca-tour-skip-count",
          dcaTourSkipCount !== null && dcaTourSkipCount !== "null"
            ? Number(dcaTourSkipCount) + 1
            : 1,
        );
      }

      if (
        (dcaTourSkipCount !== undefined || dcaTourSkipCount !== null) &&
        Number(dcaTourSkipCount) >= 3
      ) {
        LocalStorageService.removeItem("dca-tour-skip-count");
        updateOnboardingStatusMutation.mutate({
          status: "PRE_KYC",
          isWeb: true,
        });
      }
    }

    if (
      pathname === "/dashboard" &&
      isInitialDashboardTourComplete &&
      type === EVENTS.TOUR_END
    ) {
      updateOnboardingStatusMutation.mutate({ status: "PRE_KYC", isWeb: true });
      setTourState((prevTourState) => ({
        ...prevTourState,
        run: false,
        stepIndex: 0,
      }));
    }

    if (pathname === "/invest" && index === 0) {
      SessionStorageService.setItem("is-initial-dashboard-tour-complete", true);
    }

    if (([STATUS.FINISHED, STATUS.SKIPPED] as Array<string>).includes(status)) {
      setTourState((prevTourState) => ({
        ...prevTourState,
        run: false,
        stepIndex: 0,
      }));
      if (status !== STATUS.FINISHED) {
        LocalStorageService.setItem(
          "dca-tour-skip-date",
          new Date().toISOString(),
        );
        LocalStorageService.setItem(
          "dca-tour-skip-count",
          dcaTourSkipCount !== null && dcaTourSkipCount !== "null"
            ? Number(dcaTourSkipCount) + 1
            : 1,
        );
      }
      if (
        (dcaTourSkipCount !== undefined || dcaTourSkipCount !== null) &&
        Number(dcaTourSkipCount) + 1 >= 3
      ) {
        LocalStorageService.removeItem("dca-tour-skip-count");
        updateOnboardingStatusMutation.mutate({
          status: "PRE_KYC",
          isWeb: true,
        });
      }

      if (status === STATUS.FINISHED) {
        preKycGotoNextSection(navigate, pathname);
      }
    } else if (
      ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as Array<string>).includes(
        type,
      )
    ) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setTourState((prevTourState) => ({
        ...prevTourState,
        stepIndex: nextStepIndex,
      }));
    }
  };

  const postKycHandleJoyrideCallback = ({
    action,
    index,
    status,
    type,
  }: CallBackProps) => {
    if (action === ACTIONS.CLOSE) {
      setTourState((prevTourState) => ({
        ...prevTourState,
        run: false,
        stepIndex: 0,
      }));
      if (status !== STATUS.PAUSED && status !== STATUS.FINISHED) {
        LocalStorageService.setItem(
          "dca-tour-skip-date",
          new Date().toISOString(),
        );
        LocalStorageService.setItem(
          "dca-tour-skip-count",
          dcaTourSkipCount !== null && dcaTourSkipCount !== "null"
            ? Number(dcaTourSkipCount) + 1
            : 1,
        );
      }
      if (
        (dcaTourSkipCount !== undefined || dcaTourSkipCount !== null) &&
        Number(dcaTourSkipCount) >= 3
      ) {
        LocalStorageService.removeItem("dca-tour-skip-count");
        updateOnboardingStatusMutation.mutate({
          status: "POST_KYC",
          isWeb: true,
        });
      }
    }
    if (pathname === "/refer-a-friend" && type === EVENTS.TOUR_END) {
      updateOnboardingStatusMutation.mutate({
        status: "POST_KYC",
        isWeb: true,
      });
      setTourState((prevTourState) => ({
        ...prevTourState,
        run: false,
        stepIndex: 0,
      }));
    }

    // NOTE: When coming to wallet page, sidebar should be closed
    if (
      isSidebarOpen &&
      isScreenWidthSmallerThan1024 &&
      pathname === "/wallet" &&
      index === 0
    ) {
      toggleSidebar();
    }

    if (([STATUS.FINISHED, STATUS.SKIPPED] as Array<string>).includes(status)) {
      setTourState((prevTourState) => ({
        ...prevTourState,
        run: false,
        stepIndex: 0,
      }));

      // NOTE: Skipping on welcome message causes the entire tour to be marked as complete
      if (status === STATUS.SKIPPED && index === 0) {
        updateOnboardingStatusMutation.mutate({
          status: "POST_KYC",
          isWeb: true,
        });
        LocalStorageService.setItem(
          "dca-tour-skip-date",
          new Date().toISOString(),
        );
        LocalStorageService.setItem(
          "dca-tour-skip-count",
          dcaTourSkipCount !== null && dcaTourSkipCount !== "null"
            ? Number(dcaTourSkipCount) + 1
            : 1,
        );
        if (
          (dcaTourSkipCount !== undefined || dcaTourSkipCount !== null) &&
          Number(dcaTourSkipCount) + 1 >= 3
        ) {
          LocalStorageService.removeItem("dca-tour-skip-count");
          updateOnboardingStatusMutation.mutate({
            status: "POST_KYC",
            isWeb: true,
          });
        }
      }

      if (status === STATUS.FINISHED) {
        postKycGotoNextSection(navigate, pathname);
      }
    } else if (
      ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as Array<string>).includes(
        type,
      )
    ) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setTourState((prevTourState) => ({
        ...prevTourState,
        stepIndex: nextStepIndex,
      }));
    }
  };

  const dcaHandleJoyrideCallback = ({
    action,
    index,
    status,
    type,
  }: CallBackProps) => {
    if (action === ACTIONS.CLOSE) {
      setTourState((prevTourState) => ({
        ...prevTourState,
        run: false,
        stepIndex: 0,
      }));
      if (status !== STATUS.PAUSED && status !== STATUS.FINISHED) {
        LocalStorageService.setItem(
          "dca-tour-skip-date",
          new Date().toISOString(),
        );
        LocalStorageService.setItem(
          "dca-tour-skip-count",
          dcaTourSkipCount !== null && dcaTourSkipCount !== "null"
            ? Number(dcaTourSkipCount) + 1
            : 1,
        );
      }
      if (
        (dcaTourSkipCount !== undefined || dcaTourSkipCount !== null) &&
        Number(dcaTourSkipCount) >= 3
      ) {
        updateOnboardingStatusMutation.mutate({
          status: "LATEST_FEATURE",
          isWeb: true,
        });
        LocalStorageService.removeItem("dca-tour-skip-count");
      }
    }
    if (pathname === "/dca/details/bitcoin/2" && type === EVENTS.TOUR_END) {
      setTourState((prevTourState) => ({
        ...prevTourState,
        run: false,
        stepIndex: 0,
      }));

      updateOnboardingStatusMutation.mutate({
        status: "LATEST_FEATURE",
        isWeb: true,
      });
      LocalStorageService.removeItem("dca-tour-skip-count");
    }

    // NOTE: When coming to dca page, sidebar should be closed
    if (
      isSidebarOpen &&
      isScreenWidthSmallerThan1024 &&
      pathname === "/dca" &&
      index === 0
    ) {
      toggleSidebar();
    }

    if (([STATUS.FINISHED, STATUS.SKIPPED] as Array<string>).includes(status)) {
      if (status === STATUS.SKIPPED && index === 0) {
        setTourState((prevTourState) => ({
          ...prevTourState,
          run: false,
          stepIndex: 0,
        }));
        LocalStorageService.setItem(
          "dca-tour-skip-date",
          new Date().toISOString(),
        );
        LocalStorageService.setItem(
          "dca-tour-skip-count",
          dcaTourSkipCount !== null && dcaTourSkipCount !== "null"
            ? Number(dcaTourSkipCount) + 1
            : 1,
        );
        if (
          (dcaTourSkipCount !== undefined || dcaTourSkipCount !== null) &&
          Number(dcaTourSkipCount) >= 3
        ) {
          updateOnboardingStatusMutation.mutate({
            status: "LATEST_FEATURE",
            isWeb: true,
          });
          LocalStorageService.removeItem("dca-tour-skip-count");
        }
      }

      if (status === STATUS.FINISHED) {
        LocalStorageService.removeItem("dca-tour-skip-count");
        dcaGotoNextSection(navigate, pathname);
      }
    } else if (
      ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as Array<string>).includes(
        type,
      ) &&
      action !== ACTIONS.CLOSE
    ) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setTourState((prevTourState) => ({
        ...prevTourState,
        stepIndex: nextStepIndex,
      }));
    }
  };

  const handleJoyrideCallback = (() => {
    if (isKycDone || isPreKycTourComplete) {
      if (isPostKycTourComplete) {
        // NOTE: will be replaced by callback of current latest feature
        return dcaHandleJoyrideCallback;
      } else {
        return postKycHandleJoyrideCallback;
      }
    } else {
      return preKycHandleJoyrideCallback;
    }
  })();

  useEffect(() => {
    // NOTE: for 2nd step in mobile prekyc, postKyc & dca tour
    if (
      isScreenWidthSmallerThan1024 &&
      isSidebarOpen &&
      pathname === "/dashboard" &&
      (stepIndex === 1 || stepIndex === 0)
    ) {
      const nextStepIndex = stepIndex + 1;
      setTourState((prevTourState) => ({
        ...prevTourState,
        stepIndex: nextStepIndex,
      }));
    }
  }, [isScreenWidthSmallerThan1024, isSidebarOpen, pathname, stepIndex]);

  useEffect(() => {
    if (isKycDone && nextTourToBeShown === "PRE_KYC") {
      updateOnboardingStatusMutation.mutate({ status: "PRE_KYC", isWeb: true });
    }
  }, [isKycDone, nextTourToBeShown, updateOnboardingStatusMutation]);
  useEffect(() => {
    const beginTour = () =>
      setTourState((prevTourState) => ({ ...prevTourState, run: true }));

    if (userOnboardingStatus.isSuccess) {
      if (!isKycDone && !isPreKycTourComplete) {
        if (
          dcaTourSkipDate !== null &&
          dayjs(dcaTourSkipDate).add(24, "hour").toDate() < new Date()
        ) {
          beginTour();
        } else if (dcaTourSkipDate === null || dcaTourSkipDate === "null") {
          beginTour();
        }
        // beginTour();
      } else if (isKycDone && isPreKycTourComplete && !isPostKycTourComplete) {
        if (
          dcaTourSkipDate !== null &&
          dayjs(dcaTourSkipDate).add(24, "hour").toDate() < new Date()
        ) {
          beginTour();
        } else if (dcaTourSkipDate === null || dcaTourSkipDate === "null") {
          beginTour();
        }
        // beginTour();
      } else if (
        isPreKycTourComplete &&
        isPostKycTourComplete &&
        !isDcaTourComplete
      ) {
        if (
          dcaTourSkipDate !== null &&
          dayjs(dcaTourSkipDate).add(24, "hour").toDate() < new Date()
        ) {
          beginTour();
        } else if (dcaTourSkipDate === null || dcaTourSkipDate === "null") {
          beginTour();
        }
      }
    }
  }, [
    userOnboardingStatus.isSuccess,
    isDcaTourComplete,
    isKycDone,
    isPostKycTourComplete,
    isPreKycTourComplete,
    dcaTourSkipDate,
  ]);

  return {
    run,
    stepIndex,
    handleJoyrideCallback,
  };
}
