import React from "react";
import CookieConsent from "react-cookie-consent";
import { consentCookieName, nordlLandingUrl } from "../config/config";
import { cookieConsentBtnStyle } from "../config/jsStyles";
import useAnalyticsAndTracking from "../hooks/useAnalyticsAndTracking";

function CookieConsentOverlay() {
  const { grantConsent, revokeConsent } = useAnalyticsAndTracking();

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      declineButtonText="Decline"
      cookieName={consentCookieName}
      style={{ background: "#172683" }}
      buttonStyle={{
        backgroundColor: "#2BA24C",
        ...cookieConsentBtnStyle,
      }}
      declineButtonStyle={{
        backgroundColor: "#373737",
        ...cookieConsentBtnStyle,
      }}
      expires={150}
      overlay
      enableDeclineButton
      flipButtons
      onAccept={grantConsent}
      onDecline={revokeConsent}
    >
      <p>
        Let us give you a better experience on norDL. We use tracking,
        analytics, and other cookies for a personalized experience on the
        platform. Read our&nbsp;
        <a
          href={`${nordlLandingUrl}/privacy-policy#cookies`}
          target="_blank"
          className="underline"
          rel="noopener noreferrer"
        >
          cookie policy
        </a>
        &nbsp;to know more.
      </p>
    </CookieConsent>
  );
}

export default CookieConsentOverlay;
