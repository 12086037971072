import React, { memo } from "react";
import numbro from "numbro";
import useAssetPrices from "../hooks/useAssetPrices";
import PropTypes from "prop-types";

function TokenImg({ tokenImgSrc, tokenName }) {
  return (
    <img
      src={tokenImgSrc}
      style={{ width: "32px", height: "32px" }}
      alt={`${tokenName.toUpperCase()} logo`}
    />
  );
}

function AssetPrices() {
  const { BTC, ETH, NORD } = useAssetPrices();

  return (
    <div className="flex justify-around text-nordColor9 font-medium text-xs">
      <div className="flex items-center gap-2 px-4">
        <TokenImg
          tokenImgSrc="https://assets.coingecko.com/coins/images/1/large/bitcoin.png?1696501400"
          tokenName="Bitcoin"
        />
        <p>
          BTC : ${numbro(BTC).format({ thousandSeparated: true, mantissa: 2 })}
        </p>
      </div>
      <div className="flex items-center gap-2 px-4 border-l border-nordColor10">
        <TokenImg
          tokenImgSrc="https://assets.coingecko.com/coins/images/279/large/ethereum.png?1696501628"
          tokenName="Ether"
        />
        <p>
          ETH : ${numbro(ETH).format({ thousandSeparated: true, mantissa: 2 })}
        </p>
      </div>
      <div className="flex items-center gap-2 px-4 border-l border-nordColor10">
        <TokenImg
          tokenImgSrc="https://assets.coingecko.com/coins/images/13630/standard/nord.jpg"
          tokenName="NORD"
        />
        <p>
          NORD : $
          {numbro(NORD).format({ thousandSeparated: true, mantissa: 2 })}
        </p>
      </div>
    </div>
  );
}

TokenImg.propTypes = {
  tokenImgSrc: PropTypes.string.isRequired,
  tokenName: PropTypes.string.isRequired,
};

export default memo(AssetPrices);
