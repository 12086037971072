export const nordNxtApiEndpoint = process.env.REACT_APP_NORD_NXT_API_ENDPOINT;
export const etherScanEndpoint = process.env.REACT_APP_ETHERSCAN;
export const btcBlockExplorerEndpoint =
  process.env.REACT_APP_BTC_BLOCK_EXPLORER;
export const polygonScanEndpoint = process.env.REACT_APP_POLYGONSCAN;
export const bscScanEndpoint = process.env.REACT_APP_BSCSCAN;
export const quoraPixelId = process.env.REACT_APP_QUORA_PIXEL_ID || "";
export const redditPixelId = process.env.REACT_APP_REDDIT_PIXEL_ID || "";
export const hotjarId = process.env.REACT_APP_HJID || "";
export const ga4Id = process.env.REACT_APP_GA4_ID;
export const sentryUrl = process.env.REACT_APP_SENTRY_URL;
export const contactUsUrl = process.env.REACT_APP_CONTACT_US_URL;
export const nordNxtWebUrl = process.env.REACT_APP_NORD_NXT_WEB_URL;
export const bitcoinNetworkType = process.env.REACT_APP_BITCOIN_NETWORK_TYPE;
export const nordlLandingUrl = process.env.REACT_APP_NORD_NXT_LANDING_URL;
export const envName = process.env.REACT_APP_ENV;
export const tawkToPropertyId = process.env.REACT_APP_TAWK_TO_PROPERTY_ID;
export const tawkToWidgetId = process.env.REACT_APP_TAWK_TO_WIDGET_ID;
export const onrampAppId = process.env.REACT_APP_ONRAMP_APP_ID || "";
export const dataSecretKey = process.env.REACT_APP_DATA_SECRET_KEY || "";
export const vapidKey = process.env.REACT_APP_VAPID_KEY || "";
export const apiKey = process.env.REACT_APP_API_KEY;
export const authDomain = process.env.REACT_APP_AUTHDOMAIN;
export const projectId = process.env.REACT_APP_PROJECT_ID;
export const storageBucket = process.env.REACT_APP_STORAGE_BUCKET;
export const messagingSenderId = process.env.REACT_APP_MESSAGING_SENDER_ID;
export const appId = process.env.REACT_APP_APP_ID;
export const playstoreLink = process.env.REACT_APP_PLAYSTORE_LINK;

export const tenRaisedToEighteen = 1e18;
export const mainPrecision = 36;
export const timerLimit = 45;
export const minDesktopLayoutSize = 1024;
export const mobileLayoutSize = 400;
export const withdrawFee = 0;
export const minNetworkConfirmations = 15;
export const fiveMinutesInMs = 300000;
export const fundUnitPrecision = 18;

export const savedSeconds = "savedSeconds";
export const consentCookieName = "isCookiesConsentGranted";
export const tokenExpiredErr = "Token expired";
export const tokenExpiredErrCode = 4010;
export const tokenInvalidErrCode = 4005;
export const joiningBonus = "Joining Bonus";
export const referralBonus = "Referral";

// BTC-ETH ratio & Crypto bluechip fund poolIds
export const topPoolsIds = [11, 7];

export const timerComponentKeys = {
  loginVerification: "login-verification",
  signUpVerification: "signup-verification",
  verifyChangePassword: "verify-change-password",
  withdrawVerification: "withdraw-verification",
  noticeCarousel: "notice-carousel",
  appAccessToken: "app-access-token",
};

export const dropdownIds = {
  coin: "coin-dd",
  chain: "chain-dd",
  fundname: "fundname-dd",
  frequency: "frequency-dd",
  instalmentDate: "instalmentDate-dd",
  orderTypes: "orderTypes-dd",
  orderStatuses: "orderStatuses-dd",
};

export const yearsDropdown = [
  { value: 1, label: "1 year" },
  { value: 2, label: "2 years" },
  { value: 3, label: "3 years" },
  { value: 4, label: "4 years" },
  { value: 5, label: "5 years" },
  { value: 6, label: "6 years" },
  { value: 7, label: "7 years" },
  { value: 8, label: "8 years" },
  { value: 9, label: "9 years" },
  { value: 10, label: "10 years" },
];

export const txnStatuses = {
  IN_PROGRESS: "In Progress",
  COMPLETED: "Completed",
  FAILED: "Failed",
};

export const networkType = {
  ETH: "ETH",
  BTC: "BTC",
  POLYGON: "POLYGON",
  TRON: "TRON",
  TRX: "TRX",
};

export const txnTypes = [
  { value: "All", label: "All" },
  { value: "Deposit", label: "Deposit" },
  { value: "Withdraw", label: "Withdraw" },
];

export const precisionTypes = {
  wei: "WEI",
  ether: "ETHER",
  none: "NONE",
};

export const timePeriodData = [
  { value: 1, label: "1M" },
  { value: 6, label: "6M" },
  { value: 12, label: "1Y" },
  { value: 36, label: "3Y" },
  { value: 60, label: "5Y" },
];

export const orderTypes = [
  { label: "All types", value: "ALL" },
  { label: "Investment", value: "INVESTMENT" },
  { label: "Redemption", value: "REDEMPTION" },
];

export const orderStatuses = {
  success: "SUCCESS",
  pending: "PENDING",
  cancelled: "CANCELLED",
  inProcess: "IN PROCESS",
  expired: "REWARD EXPIRED",
};

export const orderStatusOptions = [
  { label: "All statuses", value: "ALL" },
  { label: "Success", value: "SUCCESS" },
  { label: "Cancelled", value: "CANCELLED" },
  { label: "Pending", value: "PENDING" },
];

export const investmentTimelines = [
  { label: "Weekly", value: 7 },
  { label: "Monthly", value: 30 },
  { label: "Yearly", value: 365 },
];

export const sipFrequencies = {
  7: { label: "Weekly", value: 7 },
  15: { label: "15-days", value: 15 },
  30: { label: "Monthly", value: 30 },
  90: { label: "Quarterly", value: 90 },
};

export const sipFilterTypes = {
  all: { label: "All", value: "ALL" },
  active: { label: "Active", value: "ACTIVE" },
  paused: { label: "Paused", value: "PAUSED" },
  cancelled: { label: "Cancelled", value: "CANCELLED" },
};

export const sipStatuses = {
  active: "ACTIVE",
  cancelled: "CANCELLED",
  paused: "PAUSED",
  completed: "COMPLETED",
};

export const sipActions = {
  create: "CreateSip",
  pause: "PauseSip",
  resume: "ResumeSip",
  modify: "ModifySip",
  delete: "DeleteSip",
};

export const defaultRecurngInvstmntInstalmentDate = { label: "1st", value: 1 };

export const gaEventCats = {
  installCta: "Install App",
  sidebar: "Sidebar",
  navbar: "Navbar",
  dashboard: "Dashboard",
  dca: "Dca",
  allDcas: "AllDca",
  dcaDetails: "DcaDetails",
  invest: "Invest",
  investDetails: "InvestDetails",
  sip: "SIP",
  portfolio: "Portfolio",
  orders: "Orders",
  wallet: "Wallet",
  deposit: "Deposit",
  withdraw: "Withdraw",
  transactions: "Transactions",
  settings: "Settings",
  referral: "Referral",
  auth: "Auth",
  kyc: "KYC",
  eligible: "notKycEligible",
};

export const defaultSelectedTime = { value: "0", label: "0 years" };

export const kycStatuses = {
  notInit: "notInit",
  init: "init",
  notSuccessful: "notSuccessful",
  successful: "successful",
};

export const idCheckPrefix = "idCheck";
export const applicantStatus = `${idCheckPrefix}.applicantStatus`;
export const expectedKycReviewAnswer = "GREEN";

// NOTE: Public pages where Sidebar will be hidden need to be updated here
export const sidebarHidingPublicUrls = [
  "/invest/details/",
  "/help",
  "/dca/details",
];

export const prohibitedNumberInputKeys = ["-", "e", "E"];

export const modalActions = {
  close: "Close",
  open: "Open",
};

// NOTE: used to show values coming from backend
// update when coins are updated in backend
export const coinPrecisionMap = {
  USD: 2,
  USDC: 2,
  USDT: 2,
  DAI: 2,
  ETH: 5,
  BTC: 5,
  MATIC: 2,
  BNB: 5,
};

// NOTE: used to show values put in input by users
// // update when coins are updated in backend
export const coinInputPrecisionMap = {
  USDC: 5,
  USDT: 5,
  ETH: 5,
  BTC: 5,
  MATIC: 5,
  BNB: 5,
};

// NOTE: Update map if and when more networks are added
export const onrampNetworkMap = {
  eth: "erc20",
  matic: "matic20",
  bsc: "bep20",
  trx: "trc20",
};

export const metaverseDiverseIndexFund = "Metaverse Diverse Index Fund";

export const nameChangeFaqLink = "/help/faq/account/how-do-i-change-my-name/7";
export const referralBonusFaqLink =
  "/help/faq/nordl-start-invest-program/what-is-referral-bonus/3";
export const mfaFaqLink =
  "/help/faq/account/how-do-i-enable-multi-factor-authentication-for-my-account/9";
export const nordlStartInvestProgram =
  "/help/faq/nordl-start-invest-program/what-is-nor-dl-start-invest-program/0";
export const depositTutorial = "https://www.youtube.com/watch?v=WvqenJPANaM";

export const nextTourOptions = ["PRE_KYC", "POST_KYC", "LATEST_FEATURE", null];
export const LinkCheckregex =
  "((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)";
