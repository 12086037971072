import React, { useReducer } from "react";
import { DropdownIcon } from "../foundations/icon";
import { ReactComponent as HindiLanguageIcon } from "../assets/images/hindi-lang.svg";
import EnglishLanguageIcon from "../assets/images/english-lang.png";
import { useTranslation } from "react-i18next";
import langDropdownReducer from "../services/reducers/langDropdownReducer";
import PropTypes from "prop-types";

function LanguageDropdown({ position }) {
  const [{ isDropdownExpanded, lang }, langDropdownDispatch] = useReducer(
    langDropdownReducer,
    {
      isDropdownExpanded: false,
      lang: "en",
    },
  );

  const { i18n } = useTranslation();

  function changeLang(langToSet) {
    langDropdownDispatch({ type: "lang", payload: langToSet });
    i18n.changeLanguage(langToSet.toLowerCase());
  }

  return (
    <div className="relative hidden lg:block">
      <div
        className="bg-white border border-nordColor2/10 flex items-center md:p-[8px] sm:p-[6px] px-[4px] py-[2px] rounded-[25px] cursor-pointer"
        onClick={() => langDropdownDispatch({ type: "isDropdownExpanded" })}
      >
        {lang === "en" || lang === "EN" ? (
          <img src={EnglishLanguageIcon} alt="Eng" />
        ) : (
          <HindiLanguageIcon />
        )}
        <p className="md:font-bold font-semibold md:text-lg text-md px-[7px]">
          {lang}
        </p>
        {<DropdownIcon />}
      </div>
      {isDropdownExpanded ? (
        <div
          className={`bg-white flex flex-col items-center py-[10px] absolute w-full ${
            position === "bottom" ? "top-[100%]" : "bottom-[100%]"
          }`}
        >
          <div
            className="py-2 flex cursor-pointer hover:bg-gray-100 px-[10px]"
            onClick={() => changeLang("en")}
          >
            <img src={EnglishLanguageIcon} alt="Eng" />
            <p className="font-bold text-lg px-[7px]">en</p>
          </div>
          {/* <div
              className="py-2 flex cursor-pointer px-[10px] hover:bg-gray-100"
              onClick={() => changeLang("hi")}
            >
              <HindiLanguageIcon />
              <p className="font-bold text-lg px-[7px]">hi</p>
            </div> */}
        </div>
      ) : null}
    </div>
  );
}

LanguageDropdown.propTypes = {
  position: PropTypes.string,
};

export default LanguageDropdown;
