import { NavLink, useLocation } from "react-router-dom";
import {
  DashboardActiveIcon,
  DashboardIcon,
  NavbarInvestIcon,
  NavbarActiveInvestIcon,
  SipActiveIcon,
  SipInactiveIcon,
  CloseSidebarIcon,
  ReferActiveIcon,
  ReferInactiveIcon,
} from "../../foundations/icon";
import Logo from "../../assets/images/logo.svg";
import Icon from "../../assets/images/icon_logo.svg";
import SidebarItemText from "./SidebarItemText";
import WalletNavLink from "./WalletNavLink";
import { gaEventCats, sidebarHidingPublicUrls } from "../../config/config";
import sendGaEvent from "../../services/sendGaEvent";
import HelpNavLink from "./HelpNavLink";
import PortfolioNavLink from "./PortfolioNavLink";
import useUserSettings from "../../contexts/UserSettingsProvider";
import useScreenResizeListener from "../../hooks/useScreenResizeListener";
import useHandleNavlinkClick from "../../hooks/useHandleNavlinkClick";
import { useMemo, useReducer } from "react";
import sidebarSubItemsReducer from "../../services/reducers/sidebarSubItemsReducer";
import DcaNavLink from "./DcaNavLink";
import useAuth from "../../contexts/AuthProvider";

function isPathHidingSidebar(pathname) {
  return (
    sidebarHidingPublicUrls.filter((publicUrl) => pathname.includes(publicUrl))
      .length > 0
  );
}

function getNavLinkStyle(isSidebarOpen) {
  return `flex items-center ${!isSidebarOpen ? "justify-center" : undefined}`;
}

function getIconStyle(isSidebarOpen) {
  return `${isSidebarOpen ? "2xl:mr-4 mr-2" : undefined} cursor-pointer`;
}

function Sidebar() {
  const { isSidebarOpen, toggleSidebar } = useUserSettings();
  const { isScreenWidthSmallerThan1024 } = useScreenResizeListener();
  const { handleNavlinkClick } = useHandleNavlinkClick(gaEventCats.sidebar);
  const { pathname } = useLocation();
  const { user } = useAuth();

  const initialIsEachPortfolioItemVisible = pathname.includes("portfolio");
  const initialIsEachWalletItemVisible = pathname.includes("wallet");
  const initialIsEachHelpItemVisible = pathname.includes("help");
  const initialIsEachDcaItemVisible = pathname.includes("dca");

  const [
    {
      isEachDcaItemVisible,
      isEachPortfolioItemVisible,
      isEachWalletItemVisible,
      isEachHelpItemVisible,
    },
    sidebarSubItemsDispatch,
  ] = useReducer(sidebarSubItemsReducer, {
    isEachDcaItemVisible: initialIsEachDcaItemVisible,
    isEachPortfolioItemVisible: initialIsEachPortfolioItemVisible,
    isEachWalletItemVisible: initialIsEachWalletItemVisible,
    isEachHelpItemVisible: initialIsEachHelpItemVisible,
  });

  const toggleIsEachPortfolioItemVisible = () =>
    sidebarSubItemsDispatch({ type: "toggleIsEachPortfolioItemVisible" });

  const toggleIsEachWalletItemVisible = () =>
    sidebarSubItemsDispatch({ type: "toggleIsEachWalletItemVisible" });

  const toggleIsEachHelpItemVisible = () =>
    sidebarSubItemsDispatch({ type: "toggleIsEachHelpItemVisible" });

  const toggleIsEachDcaItemVisible = () =>
    sidebarSubItemsDispatch({ type: "toggleIsEachDcaItemVisible" });

  const isSidebarHidden = useMemo(
    () => isPathHidingSidebar(pathname),
    [pathname],
  );

  return (
    <div
      className={`side-navbar ${
        !user && isSidebarHidden ? "hidden" : "flex"
      } font-outfit px-4 py-9 h-full bg-nordColor13 flex-col justify-start transition-[width] duration-500 ease-in ${
        isSidebarOpen
          ? "fixed items-start w-8/12 h-screen z-50 lg:relative lg:w-[16vw] lg:z-auto 2xl:w-[20vw]"
          : "hidden items-center lg:flex lg:w-[8vw] 2xl:w-[7vw]"
      } 2xl:px-8`}
    >
      {/* Close sidebar button */}
      {isSidebarOpen ? (
        <button
          className="absolute right-1 top-[43%] cursor-pointer lg:translate-y-[50%] lg:right-4 lg:top-20"
          onClick={() => {
            toggleSidebar();
            sendGaEvent(gaEventCats.sidebar, "CloseSidebar");
          }}
        >
          <CloseSidebarIcon size={isScreenWidthSmallerThan1024 ? "36" : "27"} />
        </button>
      ) : null}

      {isSidebarOpen ? (
        <NavLink
          to="/dashboard"
          onClick={() => handleNavlinkClick("NavToDashboard")}
        >
          <img src={Logo} alt="logo" className="w-52 lg:w-48 mb-12" />
        </NavLink>
      ) : (
        <div className="w-full flex items-center justify-center">
          <NavLink
            to="/dashboard"
            onClick={() => handleNavlinkClick("NavToDashboard")}
          >
            <img src={Icon} alt="logo" className="w-12 mb-14" />
          </NavLink>
        </div>
      )}

      <div className="grow-[0.5] flex flex-col justify-around">
        <NavLink
          to="/dashboard"
          className={getNavLinkStyle(isSidebarOpen)}
          onClick={() => handleNavlinkClick("NavToDashboard")}
        >
          {({ isActive }) => (
            <>
              <div className={getIconStyle(isSidebarOpen)}>
                {isActive ? <DashboardActiveIcon /> : <DashboardIcon />}
              </div>
              <SidebarItemText
                isActive={isActive}
                text={"dashboard"}
                isSidebarOpen={isSidebarOpen}
              />
            </>
          )}
        </NavLink>

        <NavLink
          to="/invest"
          className={`invest ${getNavLinkStyle(isSidebarOpen)} mt-8`}
          onClick={() => handleNavlinkClick("NavToInvest")}
        >
          {({ isActive }) => (
            <>
              <div className={getIconStyle(isSidebarOpen)}>
                {isActive ? <NavbarActiveInvestIcon /> : <NavbarInvestIcon />}
              </div>
              <SidebarItemText
                isActive={isActive}
                text="invest"
                isSidebarOpen={isSidebarOpen}
              />
            </>
          )}
        </NavLink>

        <DcaNavLink
          isEachDcaItemVisible={isEachDcaItemVisible}
          toggleIsEachDcaItemVisible={toggleIsEachDcaItemVisible}
        />

        <NavLink
          to="/sip"
          className={`${getNavLinkStyle(isSidebarOpen)} mt-8`}
          onClick={() => handleNavlinkClick("NavToSip")}
        >
          {({ isActive }) => (
            <>
              <div className={getIconStyle(isSidebarOpen)}>
                {isActive ? <SipActiveIcon /> : <SipInactiveIcon />}
              </div>
              <SidebarItemText
                isActive={isActive}
                text="sip"
                isSidebarOpen={isSidebarOpen}
              />
            </>
          )}
        </NavLink>

        <PortfolioNavLink
          isEachPortfolioItemVisible={isEachPortfolioItemVisible}
          toggleIsEachPortfolioItemVisible={toggleIsEachPortfolioItemVisible}
        />

        <WalletNavLink
          isEachWalletItemVisible={isEachWalletItemVisible}
          toggleIsEachWalletItemVisible={toggleIsEachWalletItemVisible}
        />

        <NavLink
          to="/refer-a-friend"
          className={`${getNavLinkStyle(isSidebarOpen)} mt-8`}
          onClick={() => handleNavlinkClick("NavToRefer")}
        >
          {({ isActive }) => (
            <>
              <div className={getIconStyle(isSidebarOpen)}>
                {isActive ? <ReferActiveIcon /> : <ReferInactiveIcon />}
              </div>
              <SidebarItemText
                isActive={isActive}
                text="refer"
                isSidebarOpen={isSidebarOpen}
              />
            </>
          )}
        </NavLink>

        <HelpNavLink
          isEachHelpItemVisible={isEachHelpItemVisible}
          toggleIsEachHelpItemVisible={toggleIsEachHelpItemVisible}
        />
      </div>
    </div>
  );
}

export default Sidebar;
