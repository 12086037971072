// @ts-nocheck
import { useEffect } from "react";
import reactGa from "react-ga4";
import { ga4Id, hotjarId } from "../config/config";
import useUserSettings from "../contexts/UserSettingsProvider";

export default function useAnalyticsAndTracking() {
  const { isCookieConsentGranted, grantConsent, revokeConsent } =
    useUserSettings();

  useEffect(() => {
    if (isCookieConsentGranted) {
      // initialize ReactGA
      reactGa.initialize(ga4Id || "");
      reactGa.send("pageview");
      // intialize Hotjar
      (function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: hotjarId, hjsv: 6 };
        a = o.getElementsByTagName("head")[0];
        r = o.createElement("script");
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
    }
  }, [isCookieConsentGranted]);

  return { grantConsent, revokeConsent };
}
