import { useCallback, useEffect, useRef } from "react";
import useAuth from "../../contexts/AuthProvider";
import manageAuthToken from "../../services/manageAuthToken";
import { fiveMinutesInMs } from "../../config/config";
import LocalStorageService from "../../services/LocalStorageService";

export default function useTokenRefresher() {
  const { user, userDispatch } = useAuth();
  const ACCESS_TOKEN_TTL =
    LocalStorageService.getItem("access-token-ttl") || fiveMinutesInMs;
  // NOTE: TTL below 15 seconds will cause malfunctions
  const timeoutInMs = ACCESS_TOKEN_TTL - 10000;

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const startTokenTimeout = useCallback(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      manageAuthToken(() => {}, userDispatch);
      startTokenTimeout();
    }, timeoutInMs);
  }, [timeoutInMs, userDispatch]);

  useEffect(() => {
    if (user === null && timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    user !== null && startTokenTimeout();
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [startTokenTimeout, user]);

  return startTokenTimeout;
}
