import { useState } from "react";
import manageAuthToken from "../../services/manageAuthToken";
import useAuth from "../../contexts/AuthProvider";
import { tokenExpiredErrCode, tokenInvalidErrCode } from "../../config/config";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryKey,
} from "@tanstack/react-query";
import getTokenStatus from "../../api/auth/getTokenStatus";
import { Cookies } from "react-cookie";
import { cookieNames } from "../../config/cookieNames";
import useTokenRefresher from "./useTokenRefresher";

export default function useAuthQueryClient() {
  const { userDispatch } = useAuth();

  const startTokenTimeout = useTokenRefresher();

  const globalQueryOnError = async (
    error: any,
    query: { queryKey: QueryKey },
  ) => {
    if (error.response.data.code === tokenExpiredErrCode) {
      const onRefreshTokenSuccess = () => {
        queryClient.invalidateQueries(query.queryKey);
        startTokenTimeout();
      };
      await manageAuthToken(onRefreshTokenSuccess, userDispatch);
    } else {
      throw error;
    }
  };

  // NOTE: This will be called before every mutation
  const globalMutationOnError = async () => {
    const cookies = new Cookies();
    const accessToken = cookies.get(cookieNames.accessToken);
    const refreshToken = cookies.get(cookieNames.refreshToken);

    if (accessToken && refreshToken) {
      try {
        const response = await getTokenStatus();
        const isTokenFresh = Boolean(response?.data?.isTokenFresh);
        if (!isTokenFresh) {
          await manageAuthToken(startTokenTimeout, userDispatch);
        }
      } catch (error: any) {
        console.log("disgusting", error);
        if (error.response.data.error === tokenInvalidErrCode) {
          userDispatch({ type: "signOut" });
        } else {
          throw error;
        }
      }
    } else {
      console.log("inside usemutation");

      userDispatch({ type: "signOut" });
    }
  };

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: false,
          },
        },
        queryCache: new QueryCache({
          onError: globalQueryOnError,
        }),
        mutationCache: new MutationCache({
          onMutate: globalMutationOnError,
        }),
      }),
  );

  return queryClient;
}
