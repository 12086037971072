export const disabledBtnStyles = {
  backgroundColor: "gray",
  cursor: "not-allowed",
};

export const sliderMarkStyle = {
  fontWeight: "500",
  fontSize: "14px",
  color: "black",
};

export const cookieConsentBtnStyle = {
  fontSize: "12px",
  color: "white",
  borderRadius: "15%",
  padding: "0.5rem",
};

export const dcaInputsMobileStyle = {
  borderWidth: "1px",
  borderColor: "#304FFE",
};

export const joyrideStyles = {
  primaryColor: "#304FFE",
  textColor: "#252A48",
  zIndex: 1000,
};
