import React from "react";

function Button(props) {
  const {
    onClick,
    children,
    variant = "",
    btnStyles = {},
    disabled,
    btnTextStyles = {},
    iconStyles = {},
    // buttonColor,
    // textColor,
    ...rest
  } = props || {};

  switch (variant) {
    case "darkBtnWithArrow":
      return (
        <button
          onClick={onClick}
          style={{ ...btnStyles, ...btnTextStyles }}
          className={`flex items-center justify-between bg-nordColor8 rounded-3xl md:h-[50px] h-[45px] md:pl-[20px] pl-[15px] gap-[7px] text-white md:text-base text-sm pr-[7px]`}
          {...rest}
          disabled={disabled}
        >
          {children}
          <div
            style={{ ...iconStyles }}
            className="rounded-full bg-nordColor8 py-[12px] px-[12px]"
          >
            <svg
              width="14"
              height="12"
              viewBox="0 0 14 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 6H1M8 1L13 6L8 1ZM13 6L8 11L13 6Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </button>
      );
    case "transparentBtnWithArrow":
      return (
        <button
          style={{ ...btnStyles, ...btnTextStyles }}
          onClick={onClick}
          className={`flex items-center justify-between bg-transparent h-[50px] pl-[20px] pr-[7px] border-[1px] border-nordColor8 rounded-[25px] text-nordColor8 text-base gap-[7px]`}
          {...rest}
          disabled={disabled}
        >
          {children}
          <div className="rounded-full bg-transparent py-[12px] px-[12px] border-[1px] border-nordColor8">
            <svg
              width="14"
              height="12"
              viewBox="0 0 14 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 1L13 6L8 1ZM13 6L8 11L13 6ZM13 6H1H13Z"
                fill="#172683"
              />
              <path
                d="M13 6H1M8 1L13 6L8 1ZM13 6L8 11L13 6Z"
                stroke="#172683"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </button>
      );
    case "linkBtn":
      return (
        <button
          style={{ ...btnStyles, ...btnTextStyles }}
          onClick={onClick}
          className={`flex items-center justify-between bg-transparent text-nordColor8 lg:text-base sm:text-sm text-xs font-extrabold`}
          {...rest}
          disabled={disabled}
        >
          {children}
        </button>
      );
    case "iconBtn":
      return (
        <button
          style={{ ...btnStyles, ...btnTextStyles }}
          onClick={onClick}
          className={`rounded-full bg-white md:p-5 p-3`}
          {...rest}
          disabled={disabled}
        >
          {children}
        </button>
      );
    case "darkBtn":
      return (
        <button
          onClick={onClick}
          style={{ ...btnStyles, ...btnTextStyles }}
          className={`flex items-center justify-between bg-nordColor8 rounded-3xl h-[50px] pl-[26px] pr-[26px] text-white text-base`}
          {...rest}
          disabled={disabled}
        >
          {children}
        </button>
      );
    default:
      break;
  }
}

export default Button;
