import { useQuery } from "@tanstack/react-query";
import getCryptoPrices from "../api/wallet/getCryptoPrices";

function useAssetPrices() {
  const { data } = useQuery(["assetPrices"], getCryptoPrices, {
    // 1 min
    staleTime: 60000,
    refetchInterval: 60000,
  });

  if (Object.keys(data?.data || {})?.length > 0) {
    return data?.data;
  } else {
    return {
      LRC: 0,
      LINK: 0,
      DOGE: 0,
      SUSHI: 0,
      MANA: 0,
      AAVE: 0,
      USDC: 0,
      NORD: 0,
      XRP: 0,
      BTC: 0,
      UNI: 0,
      ETH: 0,
      BNB: 0,
      "1INCH": 0,
      AXS: 0,
      AVAX: 0,
      CRV: 0,
      YFI: 0,
      GMT: 0,
      MATIC: 0,
      TRX: 0,
      USDT: 0,
      SOL: 0,
      YGG: 0,
      ENJ: 0,
      ALGO: 0,
      MKR: 0,
      SAND: 0,
      DOT: 0,
      ADA: 0,
      ATOM: 0,
      DYDX: 0,
      EGLD: 0,
      FIL: 0,
      LDO: 0,
      COMP: 0,
      ENS: 0,
    };
  }
}

export default useAssetPrices;
