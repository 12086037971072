import axios from "axios";
import { nordNxtApiEndpoint } from "../../config/config";
import getAuthHeader from "../../utils/getAuthHeader";

export default async function getOnboardingStatus() {
  const { data } = await axios.get(
    `${nordNxtApiEndpoint}/user-onboarding-status`,
    getAuthHeader(),
  );
  return data;
}
