import { useQuery } from "@tanstack/react-query";
import useAuth from "../contexts/AuthProvider";
import getTxnList from "../api/transactions/getTxnList";

function useTxns(txnsType) {
  const { user } = useAuth();

  const txnFilter = txnsType;

  const { isSuccess, data, isFetching, refetch } = useQuery(
    ["txnList", user?.userId],
    () => getTxnList(user?.userId),
    {
      refetchOnWindowFocus: false,
      staleTime: 1000,
      placeholderData: [],
      enabled: user !== null,
    },
  );

  let transactions = data || [];

  if (isSuccess && transactions.length > 0) {
    transactions.sort(
      (txnA, txnB) => new Date(txnB.timeStamp) - new Date(txnA.timeStamp),
    );
  }

  if (transactions?.length > 0) {
    if (txnFilter.value === "Withdraw") {
      transactions = transactions.filter((txn) => txn.txnType === "Withdraw");
    } else if (txnFilter.value === "Deposit") {
      transactions = transactions.filter((txn) => txn.txnType === "Deposit");
    }
  }

  return { isFetching, transactions, txnFilter, refetch };
}

export default useTxns;
