function langDropdownReducer(state, action) {
  switch (action.type) {
    case "isDropdownExpanded":
      return { ...state, isDropdownExpanded: !state.isDropdownExpanded };
    case "lang":
      return { ...state, lang: action.payload, isDropdownExpanded: false };
    default:
      return state;
  }
}

export default langDropdownReducer;
