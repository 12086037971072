import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./assets/Stylesheet.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { AuthProvider } from "./contexts/AuthProvider.tsx";
import "./i18n";
import { UserSettingsProvider } from "./contexts/UserSettingsProvider";
import Fallback from "./components/Fallback";
import { sentryUrl } from "./config/config";
import { CookiesProvider } from "react-cookie";
import UnsecurePage from "./pages/unsecure/UnsecurePage";
import serviceWorkerDev from "./service-worker-dev";

Sentry.init({
  dsn: sentryUrl,
  integrations: [new Sentry.BrowserTracing()],
  environment: process.env.NODE_ENV,
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById("root"));

if (window.self === window.top) {
  root.render(
    <React.StrictMode>
      <Sentry.ErrorBoundary fallback={<Fallback />}>
        <CookiesProvider>
          <UserSettingsProvider>
            <AuthProvider>
              <App />
            </AuthProvider>
          </UserSettingsProvider>
        </CookiesProvider>
      </Sentry.ErrorBoundary>
    </React.StrictMode>,
  );
} else {
  root.render(<UnsecurePage />);
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorkerDev();
