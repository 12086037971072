import { useSyncExternalStore } from "react";
import { minDesktopLayoutSize, mobileLayoutSize } from "../config/config";

function subscribe(callback: () => void) {
  window.addEventListener("resize", callback);

  return () => {
    window.removeEventListener("resize", callback);
  };
}

function getSnapshot() {
  return window.innerWidth < minDesktopLayoutSize;
}
function mobileSnapshot() {
  return window.innerWidth < mobileLayoutSize;
}

export default function useScreenResizeListener() {
  const isScreenWidthSmallerThan1024 = useSyncExternalStore(
    subscribe,
    getSnapshot,
  );
  const isScreenWidthSmallerThan400 = useSyncExternalStore(
    subscribe,
    mobileSnapshot,
  );
  return { isScreenWidthSmallerThan1024, isScreenWidthSmallerThan400 };
}
