type SidebarSubItemState = {
  isEachDcaItemVisible: boolean;
  isEachPortfolioItemVisible: boolean;
  isEachWalletItemVisible: boolean;
  isEachHelpItemVisible: boolean;
};

type SidebarSubItemAction = {
  type: string;
  payload?: boolean;
};

export default function sidebarSubItemReducer(
  state: SidebarSubItemState,
  action: SidebarSubItemAction,
) {
  switch (action.type) {
    case "toggleIsEachDcaItemVisible":
      return {
        ...state,
        isEachDcaItemVisible: !state.isEachDcaItemVisible,
        isEachPortfolioItemVisible: false,
        isEachWalletItemVisible: false,
        isEachHelpItemVisible: false,
      };
    case "toggleIsEachPortfolioItemVisible":
      return {
        ...state,
        isEachDcaItemVisible: false,
        isEachPortfolioItemVisible: !state.isEachPortfolioItemVisible,
        isEachWalletItemVisible: false,
        isEachHelpItemVisible: false,
      };
    case "toggleIsEachWalletItemVisible":
      return {
        ...state,
        isEachDcaItemVisible: false,
        isEachPortfolioItemVisible: false,
        isEachWalletItemVisible: !state.isEachWalletItemVisible,
        isEachHelpItemVisible: false,
      };
    case "toggleIsEachHelpItemVisible":
      return {
        ...state,
        isEachDcaItemVisible: false,
        isEachPortfolioItemVisible: false,
        isEachWalletItemVisible: false,
        isEachHelpItemVisible: !state.isEachHelpItemVisible,
      };
    default:
      return state;
  }
}
