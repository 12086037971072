import { createContext, useContext, useReducer, MutableRefObject } from "react";
import { getCookieConsentValue } from "react-cookie-consent";
import { consentCookieName } from "../config/config";
import useScreenResizeListener from "../hooks/useScreenResizeListener";

const UserSettingsContext = createContext({
  isSidebarOpen: false,
  toggleSidebar: () => {},
  isCookieConsentGranted: false,
  isConsentCookieSet: false,
  grantConsent: () => {},
  revokeConsent: () => {},
  tawkMessengerRef: null,
  attachTawkMessengerRef: (ref: MutableRefObject<any>) => {},
});

function userSettingsReducer(
  state: {
    isSidebarOpen: boolean;
    isCookieConsentGranted: boolean;
    isConsentCookieSet: boolean;
    tawkMessengerRef: MutableRefObject<any>;
  },
  action: { type: string; payload?: any },
) {
  switch (action.type) {
    case "toggleSidebar":
      return { ...state, isSidebarOpen: !state.isSidebarOpen };
    case "grantConsent":
      return {
        ...state,
        isCookieConsentGranted: true,
        isConsentCookieSet: true,
      };
    case "revokeConsent":
      return {
        ...state,
        isCookieConsentGranted: false,
        isConsentCookieSet: true,
      };
    case "tawkMessengerRef":
      return { ...state, tawkMessengerRef: action.payload };
    default:
      return state;
  }
}

export function UserSettingsProvider({ children }: { children: JSX.Element }) {
  const initialIsConsentGranted =
    getCookieConsentValue(consentCookieName) === "true";

  const initialIsConsentCookieSet =
    getCookieConsentValue(consentCookieName) !== undefined;

  const { isScreenWidthSmallerThan1024 } = useScreenResizeListener();

  const [
    {
      isSidebarOpen,
      isCookieConsentGranted,
      isConsentCookieSet,
      tawkMessengerRef,
    },
    userSettingsDispatch,
  ] = useReducer(userSettingsReducer, {
    isSidebarOpen: isScreenWidthSmallerThan1024 ? false : true,
    isCookieConsentGranted: initialIsConsentGranted,
    isConsentCookieSet: initialIsConsentCookieSet,
    tawkMessengerRef: null,
  });

  const toggleSidebar = () => {
    userSettingsDispatch({ type: "toggleSidebar" });
  };

  const grantConsent = () => {
    userSettingsDispatch({ type: "grantConsent" });
  };

  const revokeConsent = () => {
    userSettingsDispatch({ type: "revokeConsent" });
  };

  const attachTawkMessengerRef = (ref: MutableRefObject<any>) => {
    userSettingsDispatch({ type: "tawkMessengerRef", payload: ref });
  };

  return (
    <UserSettingsContext.Provider
      value={{
        isSidebarOpen,
        toggleSidebar,
        isCookieConsentGranted,
        isConsentCookieSet,
        grantConsent,
        revokeConsent,
        tawkMessengerRef,
        attachTawkMessengerRef,
      }}
    >
      {children}
    </UserSettingsContext.Provider>
  );
}

export default function useUserSettings() {
  return useContext(UserSettingsContext);
}
