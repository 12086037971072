import axios from "axios";
import { nordNxtApiEndpoint } from "../../config/config";
import getAuthHeader from "../../utils/getAuthHeader";

export default async function signOut() {
  const { data } = await axios.post(
    `${nordNxtApiEndpoint}/sign-out`,
    {},
    getAuthHeader(),
  );
  return data;
}
