import { txnTypes, joiningBonus } from "../config/config";
import useTxns from "./useTxns";

export default function useIsJoiningBonusReceived() {
  const depositTxnType = txnTypes[1];
  const { transactions } = useTxns(depositTxnType);
  const isJoiningBonusReceived = Boolean(
    transactions?.find(
      (txn: { network: string }) => txn.network === joiningBonus,
    ),
  );
  return isJoiningBonusReceived;
}
