import React from "react";

function UnsecurePage() {
  return (
    <div
      style={{
        visibility: "visible !important",
      }}
    >
      <h1 style={{ fontSize: "2rem", color: "red" }}>
        危险/Peligro/Danger/खतरा/Perigo/Опасность/خطر/Achtung/Gevaa
        <span style={{ fontSize: "2.5rem" }}>&#9760;</span>: Malicious activity
        detected!
      </h1>
      <h2>
        If you see this page, it means that the web app link you have clicked on
        is under Clickjacking security attack.{" "}
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSe88c4djeWfb0et7kIx9eDhxMwyBKHnMr13fw_UOYmUliY2XA/viewform"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: "underline",
            textDecorationColor: "blue",
            color: "blue",
          }}
        >
          Please inform team
        </a>{" "}
        with reference of the application from where you clicked this link.
      </h2>
      <h2>
        <a
          href={window.self.location.href}
          title="norDL"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: "underline",
            textDecorationColor: "blue",
            color: "blue",
          }}
        >
          Click here
        </a>{" "}
        to access norDL web app safely.
      </h2>
    </div>
  );
}

export default UnsecurePage;
