import React from "react";
import { ToastContainer, toast } from "react-toastify";
import PropTypes from "prop-types";

import "react-toastify/dist/ReactToastify.css";
import {
  ToastieCloseIcon,
  ToastieErrorIcon,
  ToastieSuccessIcon,
  ToastieWarningIcon,
} from "../../foundations/icon";
import useScreenResizeListener from "../../hooks/useScreenResizeListener";

export const showSuccess = (msg) =>
  toast.success(msg, {
    position: "top-center",
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    icon: ({ theme, type }) => <ToastieSuccessIcon />,
  });

export const showWarning = (msg) =>
  toast.warning(msg, {
    position: "top-center",
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    icon: ({ theme, type }) => <ToastieWarningIcon />,
  });

export const showError = (msg) =>
  toast.error(msg, {
    position: "top-center",
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    icon: ({ theme, type }) => <ToastieErrorIcon />,
  });

function Toast({ type }) {
  const CloseButton = ({ closeToast }) => (
    <i onClick={closeToast}>
      <ToastieCloseIcon />
    </i>
  );

  const contextClass = {
    success: "bg-nordColor8",
    error: "bg-nordColor6",
    warning: "bg-nordColor7",
    default: "bg-nordColor8",
  };

  const { isScreenWidthSmallerThan1024 } = useScreenResizeListener();

  return (
    <ToastContainer
      position="top-center"
      autoClose={4500}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      toastClassName={({ type }) =>
        `${
          contextClass[type || "default"]
        } relative flex p-6 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer`
      }
      style={
        isScreenWidthSmallerThan1024 ? { width: "100%" } : { width: "40vw" }
      }
      bodyClassName={
        "w-full flex justify-center items-center p-3 text-base gap-4 lg:text-xl"
      }
      closeButton={CloseButton}
    />
  );
}

Toast.propTypes = {
  closeToast: PropTypes.any,
  type: PropTypes.any,
};

export default Toast;
