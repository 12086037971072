import { captureMessage } from "@sentry/react";
import transformRes from "../utils/Storage/transformRes";

class LocalStorageService {
  public static getItem(key: string) {
    if (typeof window !== "undefined") {
      const res = window.localStorage.getItem(key);
      return res ? transformRes(res) : null;
    } else {
      captureMessage("getItem (ls): window not found");
      return null;
    }
  }

  public static setItem(key: string, value: any) {
    if (typeof window !== "undefined") {
      window.localStorage.setItem(key, value);
    } else {
      captureMessage("setItem (ls): window not found");
    }
  }

  public static removeItem(key: string) {
    if (typeof window !== "undefined") {
      window.localStorage.removeItem(key);
    } else {
      captureMessage("removeItem (ls): window not found");
    }
  }

  public static clear() {
    if (typeof window !== "undefined") {
      window.localStorage.clear();
    } else {
      captureMessage("clear (ls): window not found");
    }
  }
}

export default LocalStorageService;
