import { tokenExpiredErrCode, tokenInvalidErrCode } from "../config/config";
import { cookieNames } from "../config/cookieNames";
import { Cookies } from "react-cookie";
import requestNewAccessToken from "../api/auth/requestNewAccessToken";

export default async function manageAuthToken(
  onSuccessCallback: () => void,
  userDispatch: React.Dispatch<any>,
) {
  try {
    const cookies = new Cookies();
    const response = await requestNewAccessToken();
    const newAccessToken = response?.data?.accessToken;
    cookies.set(cookieNames.accessToken, newAccessToken, { path: "/" });
    onSuccessCallback();
  } catch (error: any) {
    if (
      error.response.data.code === tokenExpiredErrCode ||
      error.response.data.error === tokenInvalidErrCode
    ) {
      userDispatch({ type: "signOut" });
    } else {
      throw error;
    }
  }
}
