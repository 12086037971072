import axios from "axios";
import { nordNxtApiEndpoint } from "../../config/config";
import getAuthHeader from "../../utils/getAuthHeader";

export default async function pushToken(encrypteddata: any) {
  const { data } = await axios.post(
    `${nordNxtApiEndpoint}/notification/add-user-token`,
    { token: encrypteddata },
    getAuthHeader(),
  );
  return data;
}
