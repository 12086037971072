import { captureMessage } from "@sentry/react";
import SessionStorageService from "./SessionStorageService";

export default function lazyRetry(componentImport) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      SessionStorageService?.getItem("retry-lazy-refreshed") === "true" ||
        "false",
    );
    // try to import the component
    componentImport()
      .then((component) => {
        // success so reset the refresh
        SessionStorageService.setItem("retry-lazy-refreshed", "false");
        resolve(component);
      })
      .catch((error) => {
        // always log the error to Sentry
        captureMessage(`Lazy load error: ${error.message}`);
        if (!hasRefreshed) {
          // not been refreshed yet
          SessionStorageService.setItem("retry-lazy-refreshed", "true");
          // retry the import by reloading the page
          captureMessage("Chunk load retry triggered");
          window.location.reload();
        } else {
          // already retried, so reject the promise
          reject(error);
        }
      });
  });
}
