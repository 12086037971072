import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

export default function SidebarItemText({ text, isActive, isSidebarOpen }) {
  const { t } = useTranslation();
  return (
    <>
      {isSidebarOpen ? (
        <h3
          className={`w-full cursor-pointer xl:text-lg text-md font-medium ${
            isActive ? "text-nordColor8" : "text-nordColor3"
          }`}
        >
          {t(text)}
        </h3>
      ) : null}
    </>
  );
}

SidebarItemText.propTypes = {
  text: PropTypes.string,
  isActive: PropTypes.bool,
  isSidebarOpen: PropTypes.bool,
};
