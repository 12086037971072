import useOnlineStatus from "../hooks/useOnlineStatus";

const OfflineLayout = ({ children }) => {
  const isOnline = useOnlineStatus();
  return (
    <>
      {!isOnline ? (
        <div className="z-50 flex flex-col text-center">
          <p className="text-black text-md text-center items-center justify-center z-50">
            No internet connection
          </p>
        </div>
      ) : null}
      {children}
    </>
  );
};
export default OfflineLayout;
