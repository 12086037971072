import React from "react";
import { NavigateFunction } from "react-router-dom";
import { Cookies } from "react-cookie";
import { showSuccess } from "../components/Toast/Toast";
import { cookieNames } from "../config/cookieNames";
import LocalStorageService from "./LocalStorageService";

function clearDataGotoLogin(
  navigate: NavigateFunction,
  userDispatch: React.Dispatch<any>,
  toastMessage: string,
) {
  const cookies = new Cookies();
  cookies.remove(cookieNames.accessToken, { path: "/" });
  cookies.remove(cookieNames.refreshToken, { path: "/" });
  const notificationStatus: any = localStorage.getItem("notification");
  const dcaTourSkipCount: any = LocalStorageService.getItem(
    "dca-tour-skip-count",
  );
  const dcaTourSkipDate: any =
    LocalStorageService.getItem("dca-tour-skip-date");
  LocalStorageService.clear();
  LocalStorageService.setItem("notification", notificationStatus);
  LocalStorageService.setItem("dca-tour-skip-count", dcaTourSkipCount);
  LocalStorageService.setItem("dca-tour-skip-date", dcaTourSkipDate);
  navigate("/");
  userDispatch({ type: "signOut" });
  showSuccess(toastMessage);
}

export default clearDataGotoLogin;
