import axios from "axios";
import { nordNxtApiEndpoint } from "../../config/config";
import getAuthHeader from "../../utils/getAuthHeader";

async function getTxnList(userId: string) {
  const { data } = await axios.get(
    `${nordNxtApiEndpoint}/get-transactions/${userId}`,
    getAuthHeader(),
  );

  return data?.data || [];
}

export default getTxnList;
