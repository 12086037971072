import { useMutation, useQueryClient } from "@tanstack/react-query";
import updateOnboardingStatus from "../../api/onboarding/updateOnboardingStatus";
import { tokenExpiredErrCode } from "../../config/config";
import { showError } from "../../components/Toast/Toast";
import { updateOnboardingStatusError } from "../../config/toastMessages";

export default function useUpdateOnboardingStatus() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateOnboardingStatus,
    onSuccess: () => {
      queryClient.invalidateQueries(["onboarding-status"]);
    },
    onError: (error: any) => {
      if (error.response.data.code !== tokenExpiredErrCode) {
        showError(
          `${updateOnboardingStatusError}: ${error.response.data.detailMessage}`,
        );
      }
    },
  });
}
